import { Img } from 'react-image';

interface Props {
  containerClass?: string;
}

export default function AppMedia({ containerClass }: Props) {
  return (
    <section id="how" className={`${containerClass} py-10`}>
      <div className="w-full justify-center items-center flex flex-wrap">
        <a
          href="https://play.google.com/store/apps/details?id=com.mbele.mobile.android&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          target="_blank"
          className="px-3"
          rel="noreferrer"
        >
          <Img
            alt="Get it on Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            width={300}
            height={83}
            style={{ objectFit: 'cover' }}
            className="mr-5"
          />
        </a>

        <a
          href="https://apps.apple.com/us/app/mbele/id1490989445?itsct=apps_box&amp;itscg=30200"
          target="_blank"
          className="px-3"
          rel="noreferrer"
        >
          <Img
            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-US?size=250x83&amp;releaseDate=1579737600"
            alt="Download on the App Store"
            width={300}
            height={83}
            style={{ borderRadius: '13px' }}
          />
        </a>
      </div>
    </section>
  );
}
