const Home = () => {
  return (
    <div>
      <div className="faq flex-grow bg-gray-50">
        <div>
          <div className="relative py-20 bg-gray-100 border-b-2 bg-subtle">
            <div className="absolute inset-0 bg-gradient-to-t from-gray-100"></div>
            <div className="relative z-10 max-w-4xl px-4 mx-auto text-center sm:px-6 lg:px-8">
              <h1 className="text-4xl font-bold text-gray-800">Frequently Asked Questions</h1>
              <p className="max-w-xl mx-auto mt-2 text-lg text-gray-500">
                Get your questions answered.
              </p>
            </div>
          </div>
        </div>
        <div className="p-4 py-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto bg-white border-b-4 divide-y rounded-lg sm:p-6 lg:p-8">
            <div>
              <div className="flex items-center justify-between p-6 text-gray-800 no-underline cursor-pointer hover:bg-gray-100">
                <h6 className="mb-0 font-bold">
                  How can I choose which camera to use on my device?
                </h6>
                <div className="text-lg text-gray-500 transition duration-200 transform">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
              <div>
                <div className="px-6 pb-6 text-gray-700">
                  When joining a Space, use the{' '}
                  <a href="https://helpdesk.thelessonspace.com/article/18-device-setup">
                    Device Setup
                  </a>{' '}
                  in the Lobby or in the Setup menu to decide which camera you'd like to use.{' '}
                </div>
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between p-6 text-gray-800 no-underline cursor-pointer hover:bg-gray-100">
                <h6 className="mb-0 font-bold">
                  How do I get started? Is there a tutorial I can follow?
                </h6>
                <div className="text-lg text-gray-500 transition duration-200 transform">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
              <div>
                <div className="px-6 pb-6 text-gray-700">
                  If you're not sure where to start with Lessonspace then we recommend looking
                  through our <a href="https://helpdesk.thelessonspace.com/">helpdesk</a> which has
                  numerous articles to help you out. To learn how to use the tools in the Space, we
                  recommend following our{' '}
                  <a href="https://helpdesk.thelessonspace.com/article/136-video-tutorials">
                    video tutorials
                  </a>
                  .{' '}
                </div>
              </div>
            </div>
            <div>
              <div className="flex items-center justify-between p-6 text-gray-800 no-underline cursor-pointer hover:bg-gray-100">
                <h6 className="mb-0 font-bold">How does billing work?</h6>
                <div className="text-lg text-gray-500 transition duration-200 transform">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
              <div>
                <div className="px-6 pb-6 text-gray-700">
                  For a detailed FAQ on how billing works, check out the{' '}
                  <a href="/pricing/#faq">Pricing page FAQ</a>
                </div>
              </div>
            </div>
            <div>
              <div className="flex items-center justify-between p-6 text-gray-800 no-underline cursor-pointer hover:bg-gray-100">
                <h6 className="mb-0 font-bold">How many people can join a space?</h6>
                <div className="text-lg text-gray-500 transition duration-200 transform">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
              <div>
                <div className="px-6 pb-6 text-gray-700">
                  Any number of users can be in a space at one time. However, only up to 10 people
                  can send and receive audio and/or video at one time. As such, if 10 people are in
                  a room and an 11th person joins they will be able to use the whiteboard and chat
                  functionality as usual but they will neither be able to send nor receive
                  audio/video to/from anyone else in the Space. If you're looking to host a larger
                  number of people in a single session, chat with us at support@thelessonspace.com.
                </div>
              </div>
            </div>
            <div>
              <div className="flex items-center justify-between p-6 text-gray-800 no-underline cursor-pointer hover:bg-gray-100">
                <h6 className="mb-0 font-bold">How reliable is Lessonspace?</h6>
                <div className="text-lg text-gray-500 transition duration-200 transform">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
              <div>
                <div className="px-6 pb-6 text-gray-700">
                  The goal of Lessonspace is to be as reliable as a pencil! This means it should
                  work perfectly, every time, whether you are a first-time user or a high-usage
                  organisation. There is an advanced troubleshooting system at work to ensure that
                  any problems on your or your clients' side are quickly made known and resolved.
                  Call quality is kept high, and latency low with dedicated servers in the following
                  regions: US East Coast, US West Coast, London, Japan, Australia, and South Africa.
                </div>
              </div>
            </div>
            <div>
              <div className="flex items-center justify-between p-6 text-gray-800 no-underline cursor-pointer hover:bg-gray-100">
                <h6 className="mb-0 font-bold">Is Lessonspace mobile and tablet friendly?</h6>
                <div className="text-lg text-gray-500 transition duration-200 transform">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
              <div>
                <div className="px-6 pb-6 text-gray-700">
                  Lessonspace is device-agnostic but is dependant on the functionality provided by
                  the web browsers of platforms (i.e. Windows, Mac OS, iOS, Android, etc.). Tablets
                  and Phones do not support screen sharing due to limitations of the firmware on
                  said devices. All other functionality is supported on mobile and tablet devices.
                  For iOS, only Safari is supported as other browsers do not support WebRTC.
                </div>
              </div>
            </div>
            <div>
              <div className="flex items-center justify-between p-6 text-gray-800 no-underline cursor-pointer hover:bg-gray-100">
                <h6 className="mb-0 font-bold">
                  What operating system do I need to use Lessonspace?
                </h6>
                <div className="text-lg text-gray-500 transition duration-200 transform">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
              <div>
                <div className="px-6 pb-6 text-gray-700">
                  You can use Lessonspace with any Operating System that supports modern browsers!
                  Read more about our Supported Devices &amp; Network Requirements{' '}
                  <a href="https://helpdesk.thelessonspace.com/article/31-supported-devices-network-requirements">
                    here
                  </a>
                  .{' '}
                </div>
              </div>
            </div>
            <div>
              <div className="flex items-center justify-between p-6 text-gray-800 no-underline cursor-pointer hover:bg-gray-100">
                <h6 className="mb-0 font-bold">What browser do I need to use Lessonspace?</h6>
                <div className="text-lg text-gray-500 transition duration-200 transform">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
              <div>
                <div className="px-6 pb-6 text-gray-700">
                  Lessonspace will work on any modern browser such as Chrome, Firefox, Safari (iOS
                  10+) or Edge. Internet Explorer 11 and older browsers are supported but without
                  audio or video. We recommend either using the latest version of Chrome or Firefox
                  as they deliver the best experience. Read more about our Supported Devices &amp;
                  Network Requirements{' '}
                  <a href="https://helpdesk.thelessonspace.com/article/31-supported-devices-network-requirements">
                    here
                  </a>
                  .{' '}
                </div>
              </div>
            </div>
            <div>
              <div className="flex items-center justify-between p-6 text-gray-800 no-underline cursor-pointer hover:bg-gray-100">
                <h6 className="mb-0 font-bold">
                  Do I need to install anything to use Lessonspace?
                </h6>
                <div className="text-lg text-gray-500 transition duration-200 transform">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
              <div>
                <div className="px-6 pb-6 text-gray-700">
                  Besides a web browser (which you are using right now to read this), Lessonspace
                  doesn't need any additional software to be installed.
                </div>
              </div>
            </div>
            <div>
              <div className="flex items-center justify-between p-6 text-gray-800 no-underline cursor-pointer hover:bg-gray-100">
                <h6 className="mb-0 font-bold">
                  Do I need a webcam and/or a microphone to use Lessonspace?
                </h6>
                <div className="text-lg text-gray-500 transition duration-200 transform">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
              <div>
                <div className="px-6 pb-6 text-gray-700">
                  If you want to make use of the live video calling feature, you will need both a
                  webcam and microphone. These come standard with most modern smartphones, tablets,
                  and laptops. If you don't have a webcam but have a microphone, the video calling
                  still works, just without the video part. All other features of Lessonspace will
                  continue to work without a webcam or microphone.
                </div>
              </div>
            </div>
            <div>
              <div className="flex items-center justify-between p-6 text-gray-800 no-underline cursor-pointer hover:bg-gray-100">
                <h6 className="mb-0 font-bold">
                  What internet speed to I need to use Lessonspace?
                </h6>
                <div className="text-lg text-gray-500 transition duration-200 transform">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
              <div>
                <div className="px-6 pb-6 text-gray-700">
                  Lessonspace requires a minimum of 256kbit/s of uplink per video publishing stream,
                  and a minimum of 256kbit/s of download per video receiving stream. Screen sharing
                  doubles this to 512kbit/s. The signalling and content itself has a negligible
                  bandwidth requirement. In total, for 1 on 1 lessons, at least a 1mbit/s
                  symmetrical connection is recommended.
                </div>
              </div>
            </div>
            <div>
              <div className="flex items-center justify-between p-6 text-gray-800 no-underline cursor-pointer hover:bg-gray-100">
                <h6 className="mb-0 font-bold">
                  I'm hearing an echo of my own voice when in a session. How do I stop this?
                </h6>
                <div className="text-lg text-gray-500 transition duration-200 transform">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
              <div>
                <div className="px-6 pb-6 text-gray-700">
                  Audio echo is almost always the result of one or more users in a session not using
                  headphones. This can cause the microphone of the users' device to accidentally
                  pick up the audio from the speakers - resulting in an echo effect. To resolve
                  this, use headphones or turn on our{' '}
                  <a href="https://helpdesk.thelessonspace.com/article/171-advanced-noise-cancellation">
                    Advanced Noise Cancellation
                  </a>{' '}
                  .{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
