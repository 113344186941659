import { Img } from 'react-image';

interface Props {
  containerClass?: string;
}

export default function AppMedia({}: Props) {
  return (
    <section id="who" className="tw-py-10">
      <div className="tw-container tw-mx-auto">
        <h2
          id="how-mbele-works-header"
          className="tw-default-text-color tw-mb-4 tw-py-3 tw-text-center tw-text-4xl tw-font-medium tw-leading-6"
        >
          Why use Mbele?
        </h2>

        <div className="tw-grid tw-grid-rows-2 tw-divide-y tw-divide-gray-200 tw-px-2 md:tw-grid-flow-col md:tw-grid-cols-3 md:tw-grid-rows-none md:tw-gap-4 md:tw-divide-y-0 md:tw-px-10">
          <div className="tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center tw-pb-8 tw-shadow-md md:tw-col-span-1">
            <h5 className="tw-default-text-color tw-my-4 tw-justify-self-start tw-text-center tw-text-2xl tw-font-medium">
              For short responses
            </h5>
            <Img
              src={require('images/landing/home/pexels-pixabay-280277.jpg')}
              width={300}
              height={300}
              style={{ objectFit: 'cover' }}
              className="tw-mr-5"
            />
            <p className="tw-mt-5 tw-w-full tw-px-5 tw-text-center tw-text-lg tw-leading-10">
              When what your audience needs to learn can be explained in a 2 - 3 minute response,
              such as follow-up questions after a lesson or appointment.
            </p>
          </div>

          <div className="tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center tw-pb-8 tw-shadow-md md:tw-col-span-1">
            <h5 className="tw-default-text-color tw-my-4 tw-justify-self-start tw-text-center tw-text-2xl tw-font-medium">
              Cheaper alternative
            </h5>
            <Img
              src={require('images/landing/home/pexels-skitterphoto-9660.jpg')}
              width={300}
              height={300}
              style={{ objectFit: 'cover' }}
              className="tw-mr-5"
            />
            <p className="tw-mt-5 tw-w-full tw-px-5 tw-pb-10 tw-text-center tw-text-lg tw-leading-10">
              When a 30 min - 60 min session is too expensive but your audience still wants to
              continue learning. Your audience will ask questions specifically on what they
              don&apos;t know.
            </p>
          </div>

          <div className="tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center tw-pb-8 tw-shadow-md md:tw-col-span-1">
            <h5 className="tw-default-text-color tw-my-4 tw-justify-self-start tw-text-center tw-text-2xl tw-font-medium">
              Fast response
            </h5>
            <Img
              src={require('images/landing/home/pexels-energepiccom-313690.jpg')}
              width={300}
              height={300}
              style={{ objectFit: 'cover' }}
              className="tw-mr-5"
            />
            <p className="tw-mt-5 tw-w-full tw-px-5 tw-pb-4 tw-text-center tw-text-lg tw-leading-10">
              When your audience wants to get a quick response from you but you don&apos;t have time
              to book a long session. Send a response as easy as an Instagram or WhatsApp message.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
