import { Img } from 'react-image';
import clsx from 'clsx';
import { Disclosure, Transition } from '@headlessui/react';

import { Container } from './Container';
import faqImage from 'images/landing/stock/faq-image.jpg';

const faqs = [
  {
    question: 'What do I do first?',
    answer:
      'Create your account, set your rates and share your profile to begin responding to feedback.',
  },
  {
    question: 'How do I withdraw my earnings?',
    answer:
      'We utilize Stripe as our payment provider, which needs to be set up before you can begin selling. Funds are transferred directly to your bank account within 3 business days after each transaction is finalized. For the initial payout, it may take up to 7 days to reach your bank account after the transaction is complete. Subsequent payouts typically occur within 3 days for transactions.',
  },
  {
    question: 'How does the pricing system work?',
    answer:
      'You pay a flat rate of $ 29 and anything you make whenever you charge clients you get to keep. We do not take commission.',
  },
  {
    question: 'How much should I charge?',
    answer:
      'This completely depends on you and your clients, but we suggest it should be anywhere between 10 - 20 % of the cost of a private class.',
  },
  {
    question: 'Is it secure?',
    answer:
      'Feedback session does not store any payment information as we depend on the Stripe third party vendor in order to process all the transactions.',
  },
  {
    question: 'What is the best way to give feedback?',
    answer:
      'Based on our findings, video and audio formats are preferred, and clients generally express the highest satisfaction when answers range between 5 to 10 minutes in length.',
  },
  {
    question: 'Do you offer a free plan?',
    answer:
      'Yes, there is a free plan that you can use to begin giving feedback. You only start paying when you exceed 5 requests a month.',
  },
];

export function Faqs() {
  return (
    <section id="faq" className="tw-overflow-hidden tw-bg-vanilla tw-pb-64 md:tw-pb-32 md:tw-pt-24">
      <Container className="tw-relative">
        <Img
          src={faqImage}
          className="tw-absolute tw-left-0 tw-right-0 tw-h-80 tw-w-full tw-object-cover tw-object-center md:tw-left-[unset] md:tw-right-6 md:tw-h-auto md:tw-w-1/2 lg:tw-right-8"
          alt="Frequently asked questions"
          sizes="(min-width: 1280px) 40rem, (min-width: 768px) 50vw, 100vw"
        />
        <div className="tw-relative tw-z-10 tw-translate-y-48 md:tw-w-4/5 md:tw-translate-y-12 lg:tw-w-2/3">
          <div className="tw-border tw-border-gray-secondary-400/60 tw-bg-gray-secondary-50 tw-px-8 tw-py-12 sm:tw-px-12 sm:tw-py-16 lg:tw-px-16 lg:tw-py-20">
            <h2 className="tw-text-4xl tw-font-semibold tw-leading-tight tw-text-slate-900 sm:tw-text-5xl sm:tw-leading-tight">
              Frequently asked questions
            </h2>
            <ul className="tw-mt-12 tw-space-y-8 tw-divide-y tw-divide-gray-secondary-400/75 sm:tw-mt-16">
              {faqs.map((faq, index) => (
                <Disclosure key={index} as="li" className={clsx(index > 0 && 'tw-pt-8', '')}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="tw-flex tw-w-full tw-items-center tw-justify-between">
                        <p className="tw-text-left tw-text-lg tw-font-semibold tw-text-slate-900 sm:tw-text-xl">
                          {faq.question}
                        </p>
                        <span
                          className={clsx(
                            open
                              ? 'tw-rotate-0 before:tw-w-0'
                              : 'tw-rotate-180 before:tw-w-4 sm:before:tw-w-[18px]',
                            "tw-relative tw-ml-4 tw-flex tw-h-4 tw-w-4 tw-duration-300 before:tw-absolute before:tw-bottom-1/2 before:tw-h-0.5 before:tw--translate-y-1/2 before:tw-rotate-90 before:tw-bg-slate-800 before:tw-transition-[width] before:tw-content-[''] after:tw-absolute after:tw-bottom-1/2 after:tw-h-0.5 after:tw-w-4 after:tw--translate-y-1/2 after:tw-bg-slate-800 after:tw-content-[''] sm:tw-h-[18px] sm:tw-w-[18px] sm:after:tw-w-[18px]",
                          )}
                        ></span>
                      </Disclosure.Button>
                      <Transition
                        enter="tw-transition tw-duration-100 tw-ease-out"
                        enterFrom="tw-transform tw-scale-95 tw-opacity-0"
                        enterTo="tw-transform tw-scale-100 tw-opacity-100"
                        leave="tw-transition tw-duration-75 tw-ease-out"
                        leaveFrom="tw-transform tw-scale-100 tw-opacity-100"
                        leaveTo="tw-transform tw-scale-95 tw-opacity-0"
                      >
                        <Disclosure.Panel className="tw-px-1 tw-pt-5 tw-text-base tw-leading-relaxed tw-text-slate-700 sm:tw-text-lg sm:tw-leading-relaxed">
                          {faq.answer}
                        </Disclosure.Panel>
                      </Transition>
                    </>
                  )}
                </Disclosure>
              ))}
            </ul>
          </div>
        </div>
      </Container>
    </section>
  );
}
