import { StoryWithStats } from './components/StoryWithStats';
import { FeaturesGridDark } from './components/FeaturesGridDark';
import { Container } from './components/Container';
import Button from 'components/Elements/Button';
import { userAuth as userAuthApi } from 'store/index';
import { useSelector } from 'hooks';
import { UserAuthType } from 'types/index';
import { APP_URL } from 'utils/constants';

export default function AboutPage() {
  const user: UserAuthType = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);

  return (
    <>
      <StoryWithStats />
      <FeaturesGridDark />

      <section className="tw-relative tw-overflow-hidden tw-bg-vanilla tw-py-16 sm:tw-py-20">
        <Container>
          <div className="tw-bg-amber-100 tw-px-4 tw-py-16 sm:tw-px-6 lg:tw-px-8 lg:tw-py-20">
            <h1 className="tw-text-center tw-text-3xl tw-font-semibold tw-leading-snug tw-text-slate-900 sm:tw-text-3xl sm:tw-leading-snug">
              Try Feedback session for free
            </h1>
            {/* <p className="tw-mx-auto tw-mt-5 tw-max-w-xl tw-text-center tw-text-lg tw-leading-relaxed tw-text-slate-700">
              Try Feedback Session for free, and immediately see your clients improve.
            </p> */}
            <div className="tw-mt-10 tw-flex tw-w-full tw-justify-center sm:tw-mt-12">
              {!user?.id ? (
                <Button href="/signup" className="tw-w-full sm:tw-w-auto">
                  Create an account
                </Button>
              ) : (
                <Button href={APP_URL} className="tw-w-full sm:tw-w-auto">
                  Dashboard
                </Button>
              )}
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
