import { useState } from 'react';
import { Img } from 'react-image';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Popper, ClickAwayListener } from '@mui/base';

import { userAuth as userAuthApi } from 'store/index';
import { useSelector } from 'hooks';
import { UserAuthType } from 'types/index';

interface MenuProps {
  isMenuOpen: boolean;
  setIsMenuOpen: (value: boolean) => void;
}

function MobileMenu({ isMenuOpen, setIsMenuOpen }: MenuProps) {
  const user: UserAuthType = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);

  return (
    <div
      id="mobile-menu"
      className={clsx(
        'tw-absolute tw-inset-x-0 tw-top-0 tw-origin-top-right tw-transform tw-p-2 tw-transition',
        !isMenuOpen && 'tw-hidden',
      )}
    >
      <div className="tw-overflow-hidden tw-rounded-lg tw-bg-white tw-shadow-md tw-ring-1 tw-ring-black tw-ring-opacity-5">
        <div className="tw-flex tw-items-center tw-justify-between tw-px-5 tw-pt-4">
          <div>
            <Img src={require('images/mbele-icon-white-sm.png')} height={50} width={50} alt="" />
          </div>
          <div className="tw--mr-2">
            <button
              type="button"
              className="tw-inline-flex tw-items-center tw-justify-center tw-rounded-md tw-bg-white tw-p-2 tw-text-gray-400 hover:tw-bg-gray-100 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-500"
              onClick={() => setIsMenuOpen(false)}
            >
              <span className="tw-sr-only">Close main menu</span>

              <svg
                className="tw-h-8 tw-w-8"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>

        <div
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="main-menu"
          className="tw-mt-6 tw-grid tw-gap-6"
          onClick={() => setIsMenuOpen(false)}
          tabIndex={0}
        >
          <Link to="/">
            <a
              className="tw-block tw-rounded-md tw-px-5 tw-text-base tw-font-medium tw-text-gray-700 hover:tw-bg-gray-50 hover:tw-text-gray-900"
              role="menuitem"
            >
              Home
            </a>
          </Link>

          <Link to="/page/features">
            <a
              className="tw-block tw-rounded-md tw-px-5 tw-text-base tw-font-medium tw-text-gray-700 hover:tw-bg-gray-50 hover:tw-text-gray-900"
              role="menuitem"
            >
              Features
            </a>
          </Link>

          <Link to="/page/sales">
            <a
              className="tw-block tw-rounded-md tw-px-5 tw-text-base tw-font-medium tw-text-gray-700 hover:tw-bg-gray-50 hover:tw-text-gray-900"
              role="menuitem"
            >
              Pricing
            </a>
          </Link>

          <div className="tw-bg-gray-100 tw-px-5 tw-py-3 tw-text-base tw-font-semibold tw-text-gray-700">
            Support
          </div>

          {/* <Link to="/page/faq">
						<a
							className="px-5 block rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
							role="menuitem"
						>
							FAQ
						</a>
					</Link> */}

          <a
            className="tw-block tw-rounded-md tw-px-5 tw-text-base tw-font-medium tw-text-gray-700 hover:tw-bg-gray-50 hover:tw-text-gray-900"
            role="menuitem"
            href="https://helpdesk.mbele.io/"
          >
            Knowledge Base
          </a>

          <div className="tw-rounded-b-lg tw-bg-gray-100 tw-p-6">
            {user?.id ? (
              <>
                <Link
                  to="https://app.mbele.io"
                  className="tw-button tw-button-primary tw-w-full tw-font-semibold"
                >
                  Sign Up
                </Link>
              </>
            ) : (
              <>
                <Link
                  to="/register"
                  className="tw-button tw-button-primary tw-w-full tw-font-semibold"
                >
                  Sign Up
                </Link>

                <p className="tw-mt-6 tw-text-center tw-text-gray-500">
                  Existing customer?{' '}
                  <Link to="/login" className="tw-text-indigo-600 hover:tw-text-indigo-500">
                    Login
                  </Link>
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default function Navbar() {
  const user: UserAuthType = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [anchorElSupport, setAnchorElSupport] = useState<null | HTMLElement>(null);

  return (
    <div className="tw-relative tw-z-20">
      <div className="tw-mx-auto tw-flex tw-h-16 tw-max-w-7xl tw-items-center tw-justify-between tw-px-4 tw-py-4 sm:tw-px-6 lg:tw-justify-start lg:tw-space-x-10 lg:tw-px-8 lg:tw-py-3">
        <div>
          <Link to="/">
            <Img src={require('images/mbele-icon-white-sm.png')} height={50} width={50} alt="" />
          </Link>
        </div>

        <ClickAwayListener onClickAway={() => setIsMenuOpen(false)}>
          <div className="tw--my-2 lg:tw-hidden">
            <button
              type="button"
              className="tw-inline-flex tw-items-center tw-justify-center tw-rounded-md tw-bg-gray-100 tw-p-2 tw-text-gray-500 hover:tw-bg-white hover:tw-text-gray-400 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-500"
              aria-expanded="false"
              id="main-menu"
              onClick={() => setIsMenuOpen(true)}
            >
              <span className="tw-sr-only">Open menu</span>
              <svg
                className="tw-h-6 tw-w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
            </button>

            <MobileMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
          </div>
        </ClickAwayListener>
        <div className="tw-hidden lg:tw-flex lg:tw-flex-1 lg:tw-items-center lg:tw-justify-between">
          <nav className="tw-flex tw-gap-6">
            <Link to="/page/features">
              <span className="tw-cursor-pointer tw-text-base tw-font-medium tw-text-gray-500 tw-no-underline hover:tw-text-gray-900">
                Features
              </span>
            </Link>

            <Link to="/page/sales">
              <span className="tw-cursor-pointer tw-text-base tw-font-medium tw-text-gray-500 tw-no-underline hover:tw-text-gray-900">
                Pricing
              </span>
            </Link>
            <ClickAwayListener onClickAway={() => setAnchorElSupport(null)}>
              <div className="dropdown">
                <span
                  className="tw-flex tw-cursor-pointer tw-items-center tw-text-base tw-font-medium tw-text-gray-500 hover:tw-text-gray-900"
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    setAnchorElSupport(anchorElSupport ? null : event.currentTarget);
                  }}
                >
                  Support
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="tw-ml-0.5 tw-h-4 tw-w-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </span>

                <Popper
                  id={Boolean(anchorElSupport) ? 'simple-popper' : undefined}
                  open={Boolean(anchorElSupport)}
                  anchorEl={anchorElSupport}
                  className="tw-z-10"
                >
                  <div>
                    <div className="tw-grid tw-rounded-md tw-bg-white tw-py-2 tw-shadow-lg">
                      {/* <Link to="/page/faq">
												<a className="cursor-pointer px-5 py-3 text-base font-medium text-gray-500 no-underline hover:text-gray-900 hover:bg-gray-100">
													FAQ
												</a>
											</Link> */}
                      <a
                        href="https://helpdesk.mbele.io/"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="tw-cursor-pointer tw-px-5 tw-py-3 tw-text-base tw-font-medium tw-text-gray-500 tw-no-underline hover:tw-bg-gray-100 hover:tw-text-gray-900"
                      >
                        Knowledge Base
                      </a>
                      <a
                        href="mailto:support@mbele.io"
                        className="tw-cursor-pointer tw-px-5 tw-py-3 tw-text-base tw-font-medium tw-text-gray-500 tw-no-underline hover:tw-bg-gray-100 hover:tw-text-gray-900"
                      >
                        Contact Support
                      </a>
                    </div>
                  </div>
                </Popper>
              </div>
            </ClickAwayListener>
          </nav>
          <div className="tw-flex tw-items-center tw-space-x-2">
            {user?.id ? (
              <>
                <Link to="https://app.mbele.io/" className="tw-button tw-button-primary">
                  Dashboard
                </Link>
              </>
            ) : (
              <>
                <Link to="/login" className="tw-button tw-button-secondary">
                  Login
                </Link>
                <Link to="/register" className="tw-button tw-button-primary">
                  Sign Up
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
