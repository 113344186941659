import { Img } from 'react-image';

import { Subtitle, TitleBadge, TitleDescription } from './components/common/heading';
import { MediaTextContainer, FeatureListContaner } from './components/feature/index';

const Home = () => {
  return (
    <div>
      <div className="tw-flex-grow tw-bg-gray-50 tw-pb-10">
        <div>
          <div className="tw-bg-subtle tw-relative tw-border-b-2 tw-bg-gray-100">
            <div className="tw-absolute tw-inset-0 tw-bg-gradient-to-t tw-from-gray-100"></div>
            <div className="tw-relative tw-z-10 tw-mx-auto tw-max-w-7xl tw-px-4 tw-py-16 sm:tw-px-6 sm:tw-py-24 lg:tw-px-8">
              <div className="tw-text-center">
                <TitleBadge>Features</TitleBadge>
                <Subtitle>Tools for Feedback</Subtitle>
                <TitleDescription>
                  Industry specific feedback tools designed to engage and retain clients by
                  accelarating their learning
                </TitleDescription>
              </div>
            </div>
            <div className="tw-absolute tw-bottom-0 tw-left-1/2 tw--translate-x-1/2 tw-translate-y-1/2 tw-transform">
              <svg
                viewBox="0 0 28 28"
                className="tw-h-8 tw-w-8 tw-translate-y-px tw-transform tw-text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.81481 9.33333L13.5629 0.786742C13.7534 0.443837 14.2466 0.443837 14.4371 0.786742L19.1852 9.33333L27.1653 13.5581C27.5201 13.7459 27.5201 14.2541 27.1653 14.4419L19.1852 18.6667L14.4371 27.2133C14.2466 27.5562 13.7534 27.5562 13.5629 27.2133L8.81481 18.6667L0.834689 14.4419C0.479947 14.2541 0.479947 13.7459 0.834688 13.5581L8.81481 9.33333Z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
          </div>
        </div>

        <MediaTextContainer
          media={
            <Img
              className="tw-h-56 tw-w-full tw-object-cover sm:tw-h-72 md:tw-h-96 lg:tw-h-full lg:tw-w-full"
              src={require('images/landing/features/whiteboard-placeholder.jpeg')}
              alt=""
            />
          }
          title="Collaborative Whiteboard"
          content="Teach visually on an infinite shared canvas
          with subject-specific tools to help students
          understand concepts."
          bgColor="tw-bg-blue-500"
          align="left"
        />

        {/* <MediaTextContainer
					media={
						<video
							src="https://a.storyblok.com/f/57171/x/3562885c75/collab_whiteboard.webm"
							poster=""
							className="object-cover w-full h-full"
							autoplay=""
							playsinline=""
							loop=""
						></video>
					}
					title="Industry-Specific Tools"
					content={
						<>
							Go beyond basic tools and teach concepts visually
							using the right tools for the job. Teach STEM
							subjects with the equation and graph editor. Or
							teach computer science on a Code tab.
							<div className="grid gap-4 pt-6 mt-6 border-t-2">
								<div className="flex pr-8 rounded-lg">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="flex-none w-7 h-7 text-indigo-500"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
										></path>
									</svg>
									<div className="ml-3 transform translate-y-0.5">
										Visual Equation and Graph Editor with
										LaTeX Support
									</div>
								</div>
								<div className="flex pr-8 rounded-lg">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="flex-none w-7 h-7 text-indigo-500"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
										></path>
									</svg>
									<div className="ml-3 transform translate-y-0.5">
										Code Editor with Syntax Highlighting
									</div>
								</div>
							</div>
						</>
					}
					bgColor="bg-blue-500"
					align="right"
				/> */}

        <FeatureListContaner
          bgColor="tw-bg-blue-500"
          title="Collaborative Whiteboard and Workspace →"
          featureList={[
            {
              title: 'Infinite Canvas',
              content: `Never run out of space or have to turn the page. Your whiteboard will just keep on going!`,
            },
            {
              title: 'Draw, Highlight, Type & Annotate',
              content: `Use a variety of pen sizes,
                highlighters, and text boxes to easily
                annotate or write.`,
            },
            {
              title: 'Shapes & Arrows',
              content: `Draw attention to parts of an image or
              document with shapes and arrows.`,
            },
            {
              title: 'Custom Backgrounds',
              content: `Switch between a whiteboard and a
              blackboard, and use grid or line
              background to guide your drawing.`,
            },

            {
              title: 'Snapshot Video to Whiteboard',
              content: `Hold up a page to your webcam or share
              your screen, then snap a screenshot
              right onto the whiteboard.`,
            },
            {
              title: 'Download Whiteboard as Image',
              content: `Save a copy of your whiteboards as an
              image on your computer, to print out or
              reference later.`,
            },
            // {
            // 	title: "Code Tabs",
            // 	content: `Pair program on Code tabs, complete with
            //   syntax highlighting for most popular
            //   language.`,
            // },
            // {
            // 	title: "Record Every Action",
            // 	content: `All actions in a Space can be recorded
            //   for playback later.`,
            // },
          ]}
        />
      </div>
    </div>
  );
};

export default Home;
