import { Img as Image } from 'react-image';

import processImage from 'images/landing/home/pexels-shkrabaanthony-5878686.jpg';
import { Container } from './Container';
import Button from 'components/Elements/Button';

export function Process() {
  return (
    <section className="tw-relative tw-overflow-hidden tw-bg-slate-700 tw-pt-24 lg:tw-py-24">
      <Container>
        <div className="tw-relative tw-z-10 tw-mx-auto tw-w-full tw-max-w-lg sm:tw-max-w-xl lg:tw-mx-0">
          <h2 className="tw-text-4xl tw-font-semibold tw-leading-tight tw-text-white sm:tw-text-5xl sm:tw-leading-tight">
            How it works
          </h2>
          <div className="tw-mt-12 tw-space-y-8 tw-divide-y tw-divide-gray-secondary-400/90 sm:tw-mt-16">
            <div className="tw-flex tw-items-center tw-space-x-10">
              <span className="tw-text-4xl tw-font-semibold tw-text-white">01</span>
              <span className="tw-text-xl tw-leading-snug tw-text-slate-50">
                Sign up and create your business profile.
              </span>
            </div>
            <div className="tw-flex tw-items-center tw-space-x-10 tw-pt-8">
              <span className="tw-text-4xl tw-font-semibold tw-text-white">02</span>
              <span className="tw-text-xl tw-leading-snug tw-text-slate-50">
                Configure your shareable link to provide to your audience.
              </span>
            </div>
            <div className="tw-flex tw-items-center tw-space-x-10 tw-pt-8">
              <span className="tw-text-4xl tw-font-semibold tw-text-white">03</span>
              <span className="tw-text-xl tw-leading-snug tw-text-slate-50">
                Respond to your audience using chat, audio or video.
              </span>
            </div>
          </div>
          <Button href="/signup" variant="ghost" color="light" className="tw-mt-16 sm:tw-mt-20">
            Get your free account
          </Button>
        </div>
      </Container>
      <div className="tw-relative tw-mt-16 tw-h-80 tw-w-full sm:tw-h-96 lg:tw-absolute lg:tw-inset-y-0 lg:tw-right-0 lg:tw-mt-0 lg:tw-h-full lg:tw-w-5/12">
        <Image
          src={processImage}
          className="tw-h-full tw-w-full tw-object-cover tw-object-right-top"
          alt="A person holding a microphone"
          sizes="(min-width: 1024px) 41.66vw, 100vw"
        />
        <div className="tw-absolute tw-inset-0 tw-bg-gradient-to-b tw-from-slate-700 tw-to-white/0 lg:tw-bg-gradient-to-r"></div>
      </div>
    </section>
  );
}
