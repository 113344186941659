import { Subtitle, TitleBadge, TitleDescription } from './components/common/heading';

const Home = () => {
  return (
    <div>
      <div className="tw-flex-grow tw-bg-gray-50">
        <div className="tw-bg-subtle tw-relative tw-border-b-2 tw-bg-gray-100">
          <div className="tw-absolute tw-inset-0 tw-bg-gradient-to-t tw-from-gray-100"></div>
          <div className="tw-relative tw-z-10 tw-mx-auto tw-max-w-7xl tw-px-4 tw-py-16 sm:tw-px-6 sm:tw-py-24 lg:tw-px-8">
            <div className="tw-text-center">
              <TitleBadge>Contact Sales</TitleBadge>
              <Subtitle>Talk to Us</Subtitle>
              <TitleDescription>
                We’d love to hear from you! Our team is happy to answer any questions and help you
                find the right solution.
              </TitleDescription>
            </div>
          </div>
          <div className="tw-absolute tw-bottom-0 tw-left-1/2 tw--translate-x-1/2 tw-translate-y-1/2 tw-transform">
            <svg
              viewBox="0 0 28 28"
              className="tw-h-8 tw-w-8 tw-translate-y-px tw-transform tw-text-gray-300"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.81481 9.33333L13.5629 0.786742C13.7534 0.443837 14.2466 0.443837 14.4371 0.786742L19.1852 9.33333L27.1653 13.5581C27.5201 13.7459 27.5201 14.2541 27.1653 14.4419L19.1852 18.6667L14.4371 27.2133C14.2466 27.5562 13.7534 27.5562 13.5629 27.2133L8.81481 18.6667L0.834689 14.4419C0.479947 14.2541 0.479947 13.7459 0.834688 13.5581L8.81481 9.33333Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
        <div className="tw-relative tw-px-4 tw-py-16 sm:tw-px-6 sm:tw-py-24 lg:tw-mx-auto lg:tw-max-w-7xl lg:tw-px-8 lg:tw-py-32">
          <div className="tw-mx-auto tw-max-w-2xl">
            <form className="tw-mt-6 tw-grid" action="https://mbele.io/contact-form/" method="post">
              <fieldset className="form-group">
                <label htmlFor="name" className="form-label">
                  Your Name
                </label>
                <input
                  id="name"
                  type="text"
                  name="name"
                  autoComplete="given-name"
                  className="form-input"
                />
              </fieldset>
              <fieldset className="form-group">
                <label htmlFor="email" className="form-label">
                  Work Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="form-input"
                />
              </fieldset>
              <fieldset className="form-group">
                <label htmlFor="company" className="form-label">
                  Company
                </label>
                <input
                  id="company"
                  type="text"
                  name="company"
                  autoComplete="organization"
                  className="form-input"
                />
              </fieldset>
              <fieldset className="form-group">
                <label
                  htmlFor="message"
                  className="tw-form-label tw-flex tw-items-center tw-justify-between"
                >
                  Anything specific you're looking for, or questions you'd like to ask?
                  <div className="tw-inline-flex tw-h-6 tw-max-w-full tw-flex-none tw-items-center tw-truncate tw-rounded-full tw-bg-gray-100 tw-text-xs tw-font-medium tw-uppercase tw-tracking-wide tw-text-gray-700">
                    <div className="tw-px-2">Optional</div>
                  </div>
                </label>
                <textarea id="message" name="message" rows={4} className="form-textarea"></textarea>
              </fieldset>
              <button type="submit" className="tw-button tw-button-primary tw-button-xl tw-w-full">
                Let's talk
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
