import { Img } from 'react-image';

interface Props {
  containerClass?: string;
}

export default function HowItWorks({ containerClass }: Props) {
  return (
    <section id="how" className={`${containerClass} tw-flex tw-flex-col tw-items-center tw-py-10`}>
      <div className="tw-container">
        <h2
          id="how-mbele-works-header"
          className="tw-default-text-color tw-py-3 tw-text-center tw-text-4xl tw-font-medium tw-leading-6"
        >
          How Mbele works
        </h2>
      </div>

      <div className="tw-container tw-mt-6">
        <div className="tw-px-50 tw-divide-y tw-divide-gray-200">
          <div className="tw-grid tw-grid-rows-2 tw-py-6 md:tw-grid-flow-col md:tw-grid-cols-5 md:tw-grid-rows-none md:tw-gap-4 md:tw-py-10">
            <div className="tw-order-2 tw-flex tw-justify-center md:tw-order-1 md:tw-col-span-3">
              <Img
                className="tw-h-64 tw-w-full tw-object-contain sm:tw-h-72 md:tw-h-96 md:tw-w-full"
                src={require('images/landing/home/create-profile.png')}
                alt=""
                width={600}
                height={500}
              />
            </div>

            <div className="tw-order-1 tw-flex tw-flex-col tw-items-center tw-justify-center md:tw-order-2 md:tw-col-span-2 md:tw-w-3/5">
              <h5 className="tw-default-text-color tw-mt-0 tw-text-center tw-text-2xl tw-font-medium md:tw-mt-10">
                1. Create Profile
              </h5>
              <p className="tw-mt-5 tw-w-full tw-px-5 tw-text-center tw-text-xl tw-leading-10 md:tw-px-2">
                Sign up and configure your business mbele.io page.
              </p>
            </div>
          </div>

          <div className="tw-grid tw-grid-rows-2 tw-py-6 md:tw-grid-flow-col md:tw-grid-cols-5 md:tw-grid-rows-none md:tw-gap-4 md:tw-py-10">
            <div className="tw-order-1 tw-flex tw-flex-col tw-items-center tw-justify-center md:tw-col-span-2">
              <h5 className="tw-default-text-color tw-mt-0 tw-text-center tw-text-2xl tw-font-medium md:tw-mt-10">
                2. Share your link
              </h5>
              <p className="tw-mt-5 tw-w-full tw-px-5 tw-text-center tw-text-xl tw-leading-10 md:tw-w-4/5">
                Share your mbele.io profile link with your audience. Your clients can ask you
                questions through unique links we send to them after each session.
              </p>
            </div>

            <div className="tw-order-2 tw-flex tw-justify-center md:tw-col-span-3 md:tw-mt-0">
              <Img
                className="tw-h-80 tw-w-full tw-object-contain sm:tw-h-72 md:tw-h-auto md:tw-w-64"
                src={require('images/landing/home/audience-share.png')}
                alt=""
                width={600}
                height={500}
              />
            </div>
          </div>

          <div className="tw-grid tw-grid-rows-2 tw-py-6 md:tw-grid-flow-col md:tw-grid-cols-5 md:tw-grid-rows-none md:tw-gap-4 md:tw-py-10">
            <div className="tw-order-2 tw-flex tw-justify-center md:tw-order-1 md:tw-col-span-3">
              <Img
                className="tw-h-64 tw-w-full tw-object-contain sm:tw-h-72 md:tw-h-96 md:tw-w-full"
                src={require('images/landing/home/phone-call-sm.png')}
                alt=""
                width={600}
                height={400}
              />
            </div>

            <div className="tw-order-1 tw-flex tw-flex-col tw-items-center tw-justify-center md:tw-order-2 md:tw-col-span-2 md:tw-w-3/5">
              <h5 className="tw-default-text-color tw-mt-0 tw-text-center tw-text-2xl tw-font-medium md:tw-mt-10">
                3. Respond to your audience
              </h5>
              <p className="tw-mt-5 tw-w-full tw-px-5 tw-text-center tw-text-xl tw-leading-10">
                Respond with a video recording, audio snippet or through chat. Respond via the web
                or the Mbele mobile app for greater flexibility when you are on the go.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
