import { ReactNode } from 'react';

export function TitleBadge({ children }: { children: ReactNode }) {
  return (
    <h1 className="tw-inline-flex tw-rounded-full tw-bg-blue-500 tw-bg-opacity-25 tw-px-4 tw-py-1.5 tw-text-xl tw-font-semibold tw-uppercase tw-leading-none tw-tracking-wider tw-text-blue-500">
      {children}
    </h1>
  );
}

export function Subtitle({ children }: { children: ReactNode }) {
  return (
    <h2 className="tw-mx-auto tw-mt-6 tw-max-w-4xl tw-text-4xl tw-font-black tw-text-gray-900 sm:tw-text-5xl sm:tw-tracking-tight lg:tw-text-6xl">
      {children}
    </h2>
  );
}

export function TitleDescription({ children }: { children: ReactNode }) {
  return (
    <p className="tw-mx-auto tw-mt-5 tw-max-w-xl tw-text-2xl tw-leading-9 tw-text-gray-500">
      {children}
    </p>
  );
}
