import { Img } from 'react-image';

import ctaImage1 from 'images/landing/home/pexels-diimejii-3466163-md.jpeg';
import ctaImage2 from 'images/landing/stock/cta-image-02.jpg';
import Button from 'components/Elements/Button';
import { userAuth as userAuthApi } from 'store/index';
import { useSelector } from 'hooks';
import { UserAuthType } from 'types/index';
import { Container } from './Container';

export function CallToAction() {
  const user: UserAuthType = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);

  return (
    <section className="tw-overflow-hidden tw-bg-amber-100 tw-py-20 md:tw-py-28 lg:tw-py-32">
      <Container className="tw-relative tw-items-center md:tw-grid md:tw-grid-cols-12 md:tw-gap-12">
        <div className="tw-mx-auto tw-max-w-lg md:tw-col-span-6 md:tw-mx-0 lg:tw-pr-12">
          <h2 className="tw-text-center tw-text-4xl tw-font-semibold tw-leading-tight tw-text-slate-900 sm:tw-text-5xl sm:tw-leading-tight md:tw-text-left">
            Start accelerating engagement today
          </h2>
          <p className="tw-mt-6 tw-text-center tw-text-[17px] tw-leading-relaxed tw-text-slate-700 sm:tw-text-lg sm:tw-leading-relaxed md:tw-text-left">
            Engage and retain clients by giving them feedback after a session. Accelerate their
            learning process so they keep coming back for more.
          </p>
          {!user?.id && (
            <div className="tw-mt-10 tw-flex tw-w-full tw-justify-center md:tw-justify-start">
              <Button href="/signup">Get your free account</Button>
            </div>
          )}
        </div>
        <div className="tw-col-span-6 tw-hidden tw-grid-cols-12 md:tw-grid">
          <Img
            src={ctaImage2}
            className="tw-col-span-5 tw-my-auto tw-ml-px tw-h-auto tw-w-full"
            alt="CTA image 2"
            sizes="(min-width: 1280px) 15.25rem, (min-width: 768px) 20.83vw, 0"
          />
          <Img
            src={ctaImage1}
            className="tw-col-span-7 tw-h-auto tw-w-full"
            alt="CTA image 1"
            sizes="(min-width: 1280px) 21.3rem, (min-width: 768px) 29.17vw, 0"
          />
        </div>
      </Container>
    </section>
  );
}
