import { Img } from 'react-image';

import featureImage1 from 'images/landing/home/pexels-budgeron-bach-5153997-md.jpeg';
import featureImage2 from 'images/landing/home/pexels-energepic-com-27411-313690-md.jpeg';
import { Container } from './Container';

export function FeatureBlocks() {
  return (
    <section className="tw-relative tw-overflow-hidden tw-bg-vanilla tw-pb-20 tw-pt-32 lg:tw-pb-28 lg:tw-pt-24">
      <Container>
        <div className="tw-relative tw-mx-auto tw-w-full tw-max-w-lg sm:tw-max-w-xl lg:tw-mx-0 lg:tw-max-w-none">
          <div className="tw-left-0 tw-top-1/2 tw-z-10 tw-w-full lg:tw-absolute lg:tw-w-1/2 lg:tw--translate-y-1/2">
            <Img
              src={featureImage1}
              className="tw-h-auto tw-w-full"
              alt="Feature 1"
              sizes="(min-width: 1280px) 38rem, (min-width: 1024px) 50vw, (min-width: 640px) 36rem, calc(100vw - 2rem)"
            />
            {/* <div className="tw-absolute tw-right-0 tw-top-0 tw-w-64 tw--translate-y-24 tw-bg-gray-secondary-100/95 tw-p-6 tw-backdrop-blur-sm sm:tw-py-7 lg:tw-bottom-0 lg:tw-left-0 lg:tw-top-[unset] lg:tw-translate-y-24 2xl:tw--translate-x-16">
              <p className="tw-text-4xl tw-font-semibold tw-text-slate-900 xl:tw-text-5xl">Many</p>
              <p className="tw-mt-5 tw-text-slate-700">
                Educators use feedback to accelerate learning
              </p>
            </div> */}
          </div>
          <div className="tw-w-full tw-border tw-border-gray-secondary-400/60 tw-bg-gray-secondary-50 tw-px-6 tw-py-10 sm:tw-p-14 lg:tw-ml-auto lg:tw-w-7/12 lg:tw-py-32 lg:tw-pl-28 xl:tw-pl-36 xl:tw-pr-20">
            <h2 className="tw-text-4xl tw-font-semibold tw-leading-tight tw-text-slate-900 xl:tw-text-5xl xl:tw-leading-tight">
              Accelerate the learning process
            </h2>
            <p className="tw-mt-6 tw-leading-relaxed tw-text-slate-700 sm:tw-mt-8 sm:tw-text-lg sm:tw-leading-8">
              According to research, providing specific and timely feedback is the single most
              important factor that can accelerate the learning process. This approach increases
              engagement and makes your clients or students more likely to keep coming back.
            </p>
          </div>
        </div>

        <div className="tw-relative tw-mx-auto tw-mt-48 tw-w-full tw-max-w-lg sm:tw-max-w-xl lg:tw-mx-0 lg:tw-mt-32 lg:tw-max-w-none">
          <div className="tw-right-0 tw-top-1/2 tw-z-10 tw-w-full lg:tw-absolute lg:tw-w-1/2 lg:tw--translate-y-1/2">
            <Img
              src={featureImage2}
              className="tw-h-auto tw-w-full md:tw-w-11/12"
              alt="Feature 2"
            />
            {/* <div className="tw-absolute tw-left-0 tw-top-0 tw-w-64 tw--translate-y-24 tw-bg-gray-secondary-100/95 tw-p-6 tw-backdrop-blur-sm sm:tw-py-7 lg:tw-bottom-0 lg:tw-left-[unset] lg:tw-right-0 lg:tw-top-[unset] lg:tw-translate-y-24 2xl:tw-translate-x-16">
              <p className="tw-text-4xl tw-font-semibold tw-text-slate-900 xl:tw-text-5xl">20K+</p>
              <p className="tw-mt-5 tw-text-slate-700">
                Feedback sessions created to accelerate learning
              </p>
            </div> */}
          </div>
          <div className="tw-w-full tw-border tw-border-gray-secondary-400/60 tw-bg-gray-secondary-50 tw-px-6 tw-py-10 sm:tw-p-14 lg:tw-w-7/12 lg:tw-py-32 lg:tw-pr-28 xl:tw-pl-16 xl:tw-pr-36">
            <h2 className="tw-text-4xl tw-font-semibold tw-leading-tight tw-text-slate-900 xl:tw-text-5xl xl:tw-leading-tight">
              Boost your revenue
            </h2>
            <p className="tw-mt-6 tw-leading-relaxed tw-text-slate-700 sm:tw-mt-8 sm:tw-text-lg sm:tw-leading-8">
              Often, after a lesson concludes, students desire feedback to gauge their progress.
              However, traditionally, instructors provide this service for free, leading to
              uncompensated time. With Feedback Session, you can provide valuable feedback while
              also boosting your revenue.
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
}
