import { APP_HUMAN_NAME, BASE_DOMAIN } from 'utils/constants';

function Service() {
  return (
    <div>
      <h3>
        <b>The {APP_HUMAN_NAME} Service</b>
      </h3>
      <p>
        We agree to provide you with the {APP_HUMAN_NAME} Service. The Service includes all of the{' '}
        {APP_HUMAN_NAME} products, features, applications, services, technologies, and software that
        we provide to advance {APP_HUMAN_NAME}'s mission: To accelerate your learning process and
        foster a community of interests. The Service is made up of the following aspects (the
        Service):
      </p>

      {/* style="list-style: disc; color: black; display: block; -webkit-margin-after: 12px; -webkit-margin-before: 12px; -webkit-margin-end:0px; -webkit-margin-start: 0px; -webkit-padding-start: 40px;" */}
      <ul>
        <li>
          <div>
            <b>Offering opportunities to sabe, connect, communicate, discover, and get feedback.</b>
            <br />
            People are different. We want to strengthen your knowledge through shared community.
            Part of that is highlighting content, features, offers, and accounts you might be
            interested in, and offering ways for you to experience {APP_HUMAN_NAME}, based on things
            you and others do on and off {APP_HUMAN_NAME}.
          </div>
        </li>
        <li>
          <div>
            <b>Fostering a positive, inclusive, and safe environment.</b>
            <br /> We develop and use tools and offer resources to our community members that help
            to make their experiences positive and inclusive, including when we think they might
            need help. We also have systems that work to combat abuse and violations of our Terms
            and policies, as well as harmful and deceptive behavior. We use all the information we
            have-including your information-to try to keep our platform secure. We also may share
            information about misuse or harmful content with law enforcement.
          </div>
        </li>
        <li>
          <div>
            <b>
              Developing and using technologies that help us consistently serve our growing
              community.
            </b>
            <br /> Organizing and analyzing information for our growing community is central to our
            Service. A big part of our Service is creating and using cutting-edge technologies that
            help us personalize, protect, and improve our Service. Automated technologies help us
            ensure the functionality and integrity of our Service.
          </div>
        </li>

        <li>
          <div>
            <b>Research and innovation.</b>
            <br /> We use the information we have to study our Service and collaborate with others
            on research to make our Service better and contribute to the well-being of our
            community.
          </div>
        </li>
      </ul>
      <br />
    </div>
  );
}

function Commitment() {
  return (
    <div>
      <h3>
        <b>Your Commitments</b>
      </h3>
      In return for our commitment to provide the Service, we require you to make the below
      commitments to us.
      <p></p>
      <b>Who Can Use {APP_HUMAN_NAME}.</b> We want our Service to be as open and inclusive as
      possible, but we also want it to be safe, secure, and in accordance with the law. So, we need
      you to commit to a few restrictions in order to be part of the {APP_HUMAN_NAME} community.
      {/* style="list-style: disc; color: black; display: block; -webkit-margin-after: 12px; -webkit-margin-before: 12px; -webkit-margin-end:0px; -webkit-margin-start: 0px; -webkit-padding-start: 40px;" */}
      <ul>
        <li>
          <div>
            You must be at least 13 years old or the minimum legal age in your country to use{' '}
            {APP_HUMAN_NAME}.
          </div>
        </li>
        <li>
          <div>
            You must not be prohibited from receiving any aspect of our Service under applicable
            laws or engaging in payments related Services if you are on an applicable denied party
            listing.
          </div>
        </li>
        <li>
          <div>
            We must not have previously disabled your account for violation of law or any of our
            policies.
          </div>
        </li>
        <li>
          <div>You must not be a convicted sex offender.</div>
        </li>
      </ul>
      <p></p>
      <b>How You Can't Use {APP_HUMAN_NAME}.</b> Providing a safe and open Service for a broad
      community requires that we all do our part.
      <ul>
        <li>
          <div>
            <b>You can't impersonate others or provide inaccurate information.</b>
            <br /> You don't have to disclose your identity on {APP_HUMAN_NAME}, but you must
            provide us with accurate and up to date information (including registration
            information). Also, you may not impersonate someone you aren't, and you can't create an
            account for someone else unless you have their express permission.
          </div>
        </li>
        <li>
          <div>
            <b>
              You can't do anything unlawful, misleading, or fraudulent or for an illegal or
              unauthorized purpose.
            </b>
          </div>
        </li>
        <li>
          <div>
            <b>
              You can't violate (or help or encourage others to violate) these Terms or our
              policies.
            </b>
          </div>
        </li>
        <li>
          <div>
            <b>
              You can't do anything to interfere with or impair the intended operation of the
              Service.
            </b>
          </div>
        </li>
        <li>
          <div>
            <b>
              You can't attempt to create accounts or access or collect information in unauthorized
              ways.
            </b>
            <br /> This includes creating accounts or collecting information in an automated way
            without our express permission.
          </div>
        </li>
        <li>
          <div>
            <b>
              You can't attempt to buy, sell, or transfer any aspect of your account (including your
              username) or solicit, collect, or use login credentials or badges of other users.
            </b>
          </div>
        </li>
        <li>
          <div>
            <b>
              You can't post private or confidential information or do anything that violates
              someone else's rights, including intellectual property.
            </b>
            <br /> Learn more, including how to report content that you think infringes your
            intellectual property rights.
          </div>
        </li>
      </ul>
      <b>Permissions You Give to Us.</b> As part of our agreement, you also give us permissions that
      we need to provide the Service.
      {/* style="list-style: disc; color: black; display: block; -webkit-margin-after: 12px; -webkit-margin-before: 12px; -webkit-margin-end:0px; -webkit-margin-start: 0px; -webkit-padding-start: 40px;" */}
      <ul>
        <li>
          <div>
            <b>We do not claim ownership of your content, but you grant us a license to use it.</b>
            <br />
            Nothing is changing about your rights in your content. We do not claim ownership of your
            content that you post on or through the Service. Instead, when you share, post, or
            upload content that is covered by intellectual property rights (like photos or videos)
            on or in connection with our Service, you hereby grant to us a non-exclusive,
            royalty-free, transferable, sub-licensable, worldwide license to host, use, distribute,
            modify, run, copy, publicly perform or display, translate, and create derivative works
            of your content (consistent with your privacy and application settings). You can end
            this license anytime by deleting your content or account. However, content will continue
            to appear if you shared it with others and they have not deleted it.
          </div>
        </li>
        <li>
          <div>
            <b>
              Permission to use your username, profile picture, and information about your
              relationships and actions with accounts, ads, and sponsored content.
            </b>
            <br /> You give us permission to show your username, profile picture, and information
            about your actions (such as likes) or relationships (such as connections) next to or in
            connection with accounts, ads, offers, and other sponsored content that you follow or
            engage with that are displayed on {APP_HUMAN_NAME} Products, without any compensation to
            you.
          </div>
        </li>
        <li>
          <div>
            <b>You agree that we can download and install updates to the Service on your device.</b>
          </div>
        </li>
      </ul>
      <br />
    </div>
  );
}

function AdditionalRights() {
  return (
    <div>
      <h3>
        <b>Additional Rights We Retain</b>
      </h3>
      <ul>
        <li>
          <div>
            If you select a username or similar identifier for your account, we may change it if we
            believe it is appropriate or necessary (for example, if it infringes someone's
            intellectual property or impersonates another user).
          </div>
        </li>
        <li>
          <div>
            If you use content covered by intellectual property rights that we have and make
            available in our Service (for example, images, designs, videos, or sounds we provide
            that you add to content you create or share), we retain all rights to our content (but
            not yours).
          </div>
        </li>
        <li>
          <div>
            You can only use our intellectual property and trademarks or similar marks as expressly
            permitted with our prior written permission.
          </div>
        </li>
        <li>
          <div>
            You must obtain written permission from us or under an open source license to modify,
            create derivative works of, decompile, or otherwise attempt to extract source code from
            us.
          </div>
        </li>
      </ul>
      <br />
    </div>
  );
}

function ContentRemoval() {
  return (
    <div>
      {/* style="font-size: 20px; font-weight: normal;" */}
      <h3>
        <b>Content Removal and Disabling or Terminating Your Account</b>
      </h3>

      {/* style="list-style: disc; color: black; display: block; -webkit-margin-after: 12px; -webkit-margin-before: 12px; -webkit-margin-end:0px; -webkit-margin-start: 0px; -webkit-padding-start: 40px;" */}
      <ul>
        <li>
          <div>
            We can remove any content or information you share on the Service if we believe that it
            violates these Terms of Use, our policies, or we are permitted or required to do so by
            law. We can refuse to provide or stop providing all or part of the Service to you
            (including terminating or disabling your account) immediately to protect our community
            or services, or if you create risk or legal exposure for us, violate these Terms of Use
            or our policies, if you repeatedly infringe other people's intellectual property rights,
            or where we are permitted or required to do so by law. If we take action to disable or
            terminate your account, we will notify you where appropriate. If you believe your
            account has been terminated in error, or you want to disable or permanently delete your
            account, please contact our{' '}
            <a href={`mailto:support@${BASE_DOMAIN}`} target="_self">
              Support Center
            </a>
            .
          </div>
        </li>
        <li>
          <div>
            Content you delete may persist for a limited period of time in backup copies and will
            still be visible where others have shared it. This paragraph, and the section below
            called "Our Agreement and What Happens if We Disagree," will still apply even after your
            account is terminated or deleted.
          </div>
        </li>
      </ul>
      <br />
    </div>
  );
}

function Agreement() {
  return (
    <div>
      <h3>
        <b>Our Agreement and What Happens if We Disagree</b>
      </h3>
      <b>Our Agreement.</b>
      <ul>
        <li>
          <div>
            If any aspect of this agreement is unenforceable, the rest will remain in effect.
          </div>
        </li>
        <li>
          <div>
            Any amendment or waiver to our agreement must be in writing and signed by us. If we fail
            to enforce any aspect of this agreement, it will not be a waiver.
          </div>
        </li>
        <li>
          <div>We reserve all rights not expressly granted to you.</div>
        </li>
      </ul>
      <b>Who Has Rights Under this Agreement.</b>
      <ul>
        <li>
          <div>This agreement does not give rights to any third parties.</div>
        </li>
        <li>
          <div>
            You cannot transfer your rights or obligations under this agreement without our consent.
          </div>
        </li>
        <li>
          <div>
            Our rights and obligations can be assigned to others. For example, this could occur if
            our ownership changes (as in a merger, acquisition, or sale of assets) or by law.
          </div>
        </li>
      </ul>
      <br />
      <b>Who Is Responsible if Something Happens.</b>
      <ul>
        <li>
          <div>
            Our Service is provided "as is," and we can't guarantee it will be safe and secure or
            will work perfectly all the time. TO THE EXTENT PERMITTED BY LAW, WE ALSO DISCLAIM ALL
            WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT.
          </div>
        </li>
        <li>
          <div>
            We also don’t control what people and others do or say, and we aren’t responsible for
            their (or your) actions or conduct (whether online or offline) or content (including
            unlawful or objectionable content). We also aren’t responsible for services and features
            offered by other people or companies, even if you access them through our Service.
          </div>
        </li>
        <li>
          <div>
            Our responsibility for anything that happens on the Service (also called "liability") is
            limited as much as the law will allow. If there is an issue with our Service, we can't
            know what all the possible impacts might be. You agree that we won't be responsible
            ("liable") for any lost profits, revenues, information, or data, or consequential,
            special, indirect, exemplary, punitive, or incidental damages arising out of or related
            to these Terms, even if we know they are possible. This includes when we delete your
            content, information, or account.
          </div>
        </li>
      </ul>
      <b>How We Will Handle Disputes.</b>
      <blockquote>
        {' '}
        If you are a consumer, the laws of the country in which you reside will apply to any claim,
        cause of action, or dispute you have against us that arises out of or relates to these Terms
        ("claim"), and you may resolve your claim in any competent court in that country that has
        jurisdiction over the claim. In all other cases, you agree that the claim must be resolved
        exclusively in the U.S. District Court for the Northern District of California or a state
        court located in San Mateo County, that you submit to the personal jurisdiction of either of
        these courts for the purpose of litigating any such claim, and that the laws of the State of
        California will govern these Terms and any claim, without regard to conflict of law
        provisions.{' '}
      </blockquote>
      <b>Unsolicited Material.</b>
      <blockquote>
        {' '}
        We always appreciate feedback or other suggestions, but may use them without any
        restrictions or obligation to compensate you for them, and are under no obligation to keep
        them confidential.
      </blockquote>
      <br />
    </div>
  );
}

export default function Tos() {
  return (
    <div className="tw-py-12" id="tickets">
      <div className="row me-row content-ct">
        <h2 className="tw-text-center tw-font-bold tw-text-3xl">Terms of Use</h2>
        <div className="col-sm-12 col-md-12 col-lg-12 content">
          <div id="faq-page">
            <div
              style={{
                fontSize: '16px',
                lineHeight: 1.5,
                padding: '0 8px',
                margin: 'auto',
                marginTop: '40px',
                maxWidth: '960px',
              }}
            >
              <div>
                Welcome to {APP_HUMAN_NAME}! These Terms of Use govern your use of {APP_HUMAN_NAME}{' '}
                and provide information about the {APP_HUMAN_NAME} Service, outlined below. When you
                create an {APP_HUMAN_NAME} account or use {APP_HUMAN_NAME}, you agree to these
                terms. <br />
                These Terms of Use constitute an agreement between you and {APP_HUMAN_NAME}, Inc.{' '}
                <br />
              </div>
              <Service />
              <Commitment />
              <AdditionalRights />
              <ContentRemoval />
              <Agreement />
              <div>
                <h3>
                  <b>Updating These Terms</b>
                </h3>
                We may change our Service and policies, and we may need to make changes to these
                Terms so that they accurately reflect our Service and policies. Unless otherwise
                required by law, we will notify you (for example, through our Service) before we
                make changes to these Terms and give you an opportunity to review them before they
                go into effect. Then, if you continue to use the Service, you will be bound by the
                updated Terms. If you do not want to agree to these or any updated Terms, you can
                delete your account.
                <br />
              </div>
              Revised: January, 15, 2024
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
