import { Img } from 'react-image';

const fileList = [
  { image: 'chat.png', text: 'Chat' },
  { image: 'microphone.png', text: 'Audio' },
  { image: 'video-camera.png', text: 'Video' },
  { image: 'pdf.png', text: 'Documents' },
  { image: 'phone.png', text: 'Video Call' },
];

interface Props {
  containerClass?: string;
}

export default function AppMedia({ containerClass }: Props) {
  return (
    <section id="how" className={`${containerClass} tw-flex tw-w-full tw-justify-center tw-py-7`}>
      <div className="tw-align-center tw-container tw-mx-auto tw-flex tw-justify-center">
        <div className="tw-flex tw-w-4/5 tw-flex-wrap tw-items-center tw-justify-evenly">
          {fileList.map(item => (
            <div className="tw-item tw-mr-4 tw-mt-4 tw-flex tw-flex-col" key={item.image}>
              <Img
                src={require(`images/landing/flaticon/sm/${item.image}`)}
                alt={item.text}
                width={50}
                height={50}
                style={{ objectFit: 'contain' }}
              />
              <span className="tw-mt-2 tw-text-center tw-font-light">{item.text}</span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
