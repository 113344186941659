import { Container } from './Container';

export function StoryWithStats() {
  return (
    <section className="tw-relative tw-overflow-hidden tw-bg-amber-100 tw-pb-[72px] tw-pt-16 sm:tw-pb-[500px] sm:tw-pt-24 md:tw-pb-64">
      <Container>
        <div className="tw-flex tw-flex-col sm:tw-items-center">
          <p className="tw-flex tw-items-center tw-space-x-3.5 tw-text-xl tw-font-medium tw-text-amber-900/70">
            <svg
              className=""
              xmlns="http://www.w3.org/2000/svg"
              width={28}
              height={3}
              viewBox="0 0 28 3"
              fill="none"
            >
              <line
                y1="1.5"
                x2={28}
                y2="1.5"
                stroke="currentColor"
                strokeOpacity="0.65"
                strokeWidth={3}
              />
            </svg>

            <span>Our story</span>
          </p>
          <h1 className="tw-mt-5 tw-text-4xl tw-font-semibold tw-leading-snug tw-text-slate-900 sm:tw-max-w-xl sm:tw-text-center sm:tw-text-5xl sm:tw-leading-snug md:tw-mx-auto xl:tw-mx-0">
            Increasing learning engagement
          </h1>
        </div>
        <div className="tw-mt-12 tw-flex tw-flex-col md:tw-mt-8 md:tw-flex-row md:tw-divide-x md:tw-divide-gray-secondary-400/60">
          <div className="md:tw-w-1/2 md:tw-py-8 md:tw-pr-6 lg:tw-pr-16">
            <p className="tw-text-lg tw-leading-relaxed tw-text-slate-700">
              The idea for Feedback Session came from a common challenge: How do I get unstuck
              during my assigned practice sessions? Or, how do I help my clients in between weekly
              sessions? We realized that many students and clients encounter obstacles when
              practicing independently between their scheduled sessions with instructors or
              therapists. This lack of continuous support can hinder progress and lead to
              frustration.
            </p>
            <p className="tw-mt-8 tw-text-lg tw-leading-relaxed tw-text-slate-700"></p>
          </div>
          <div className="tw-mt-8 sm:tw-mt-0 md:tw-w-1/2 md:tw-py-8 md:tw-pl-6 lg:tw-pl-16">
            <p className="tw-text-lg tw-leading-relaxed tw-text-slate-700">
              {/* At Feedback Session, we believe that constructive feedback is the cornerstone of
              personal and professional development. Our platform is dedicated to fostering a
              supportive community where individuals can give and receive meaningful feedback that
              drives improvement and success across various disciplines, including tutoring,
              dancing, therapy, and any field requiring a teacher's input. */}
              Feedback Session was created to bridge this gap. Our platform provides timely,
              personalized feedback, helping individuals overcome challenges as they arise. It
              enables instructors to offer ongoing support, ensuring clients stay on track and
              maximize their learning experiences. With Feedback Session, both students and
              instructors can achieve better results, fostering growth and success in every practice
              session.
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
}
