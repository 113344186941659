import { PricingCards } from './components/PricingCards';
import { FeaturesGrid } from './components/FeaturesGrid';
import { Faqs } from './components/Faqs';
import { CallToAction } from './components/CallToAction';

export default function PricingPage() {
  return (
    <>
      <PricingCards />
      <FeaturesGrid title="Features included in all our plans" />
      <Faqs />
      <CallToAction />
    </>
  );
}
