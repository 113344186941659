import { Img } from 'react-image';

import AppMedia from './components/Home/AppMedia';
import DownloadApp from './components/Home/DownloadApp';
import Integrations from './components/Home/Integrations';
import bgImage from 'images/landing/physio/bg.png';
import { MediaTextContainer } from './components/Features';
import { BASE_DOMAIN } from 'utils/constants';

const Home = () => {
  return (
    <div>
      <section
        className="w-full self-center table relative"
        style={{
          background: `url(${bgImage}) center center`,
          padding: '30px 0px',
          // minHeight: "40vh",
        }}
        id="home"
      >
        <div className="max-w-7xl mx-auto">
          <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h1 className="leading-11 md:leading-15 text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                  <span className="block xl:inline text-blue-800">For Physiotherapists</span>
                </h1>
                <p className="mt-3 text-base text-gray-700 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-2xl lg:mx-0 leading-8 md:leading-10">
                  Engage and retain clients by allowing them to ask follow up questions after a
                  session. Support their recovery process by responding to their questions before
                  the next session.
                </p>

                {/* {% include "./vanity-url.html" %} */}

                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  <div className="rounded-md shadow">
                    <a
                      id="mbele-create-account-link"
                      href="/"
                      className="mbele-create-account-link w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white default-bg-color hover:bg-blue-700 md:py-4 md:text-lg md:px-10"
                    >
                      Login
                    </a>
                  </div>
                </div>
              </div>
            </main>
          </div>
          <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 z-10 flex justify-center items-center">
            {/* // eslint-disable-next-line @next/next/no-img-element */}
            <Img
              className="h-56 w-full object-contain sm:h-72 md:h-96"
              src={require('images/physio/seo.gif')}
              alt=""
            />
          </div>
        </div>
      </section>

      <AppMedia containerClass="bg-secondary" />

      <section id="how" className={`py-10 flex flex-col items-center`}>
        <div className="container">
          <h2
            id="how-mbele-works-header"
            className="text-center text-4xl leading-6 font-medium py-3 default-text-color leading-10"
          >
            How Mbele works for Physiotherapists
          </h2>

          <MediaTextContainer
            media={
              <Img
                className="h-56 w-full object-cover sm:h-72 md:h-96"
                src={require('images/physio/email-capture.svg')}
                alt=""
              />
            }
            title="Automated Email"
            content="Once the therapy session ends, the client receives an email from
            Mbele with the opportunity to ask the physiotherapist a follow up question."
            bgColor="bg-blue-500"
            align="right"
          />

          <MediaTextContainer
            media={
              <Img
                className="h-56 w-full object-contain sm:h-72 md:h-96"
                src={require('images/physio/about.png')}
                alt=""
              />
            }
            title="Ask Follow-up Question"
            content={`When the user clicks the Ask follow-up question link. They are taken to the
            physiotherapists ${BASE_DOMAIN} page where the client can ask a follow up question about the session.
            The questions can be asked as a voice note, video recording or a chat message.
            The client can also book the next session in this page.`}
            bgColor="bg-red-500"
            align="left"
          />

          <MediaTextContainer
            media={
              <Img
                className="h-56 w-full object-contain sm:h-72 md:h-96"
                src={require('images/physio/app.svg')}
                alt=""
              />
            }
            title="Physiotherapist Responds to Client"
            content="The Physiotherapist then receives a notification by email, sms or mobile device and they can respond to their
            clients follow up question by a quick voice note, video recording or a chat message as well."
            bgColor="bg-indigo-500"
            align="right"
          />
        </div>
      </section>

      <DownloadApp containerClass="bg-secondary" />

      <Integrations containerClass="bg-secondary" />
    </div>
  );
};

export default Home;
