import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { Img } from 'react-image';

import Avatar from 'images/landing/avatars/avatar-01.jpg';
import homeHeroImg from 'images/landing/home-hero.jpg';
import { Container } from './Container';
import Button from 'components/Elements/Button';
import { userAuth as userAuthApi } from 'store/index';
import { useSelector } from 'hooks';
import { UserAuthType } from 'types/index';
import { APP_URL } from 'utils/constants';

export function HomeHero() {
  const user: UserAuthType = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);

  return (
    <section className="tw-relative tw-overflow-hidden tw-bg-amber-100 tw-pt-16 md:tw-pt-20 xl:tw-pt-32">
      <Container>
        <div className="tw-mx-auto tw-max-w-lg tw-pb-14 md:tw-mx-0 md:tw-max-w-none md:tw-pb-48 lg:tw-pb-52 xl:tw-max-w-xl xl:tw-pb-14">
          <h1 className="tw-text-5xl tw-font-semibold tw-leading-tighter tw-text-slate-900 md:tw-mx-auto md:tw-max-w-2xl md:tw-text-center xl:tw-mx-0 xl:tw-text-left xl:tw-text-6xl xl:tw-leading-tighter">
            Feedback based learning
          </h1>
          <ul className="tw-mt-6 tw-flex tw-flex-col tw-space-y-4 md:tw-mx-auto md:tw-mt-8 md:tw-max-w-3xl md:tw-flex-row md:tw-space-x-2 md:tw-space-y-0 lg:tw-space-x-4 xl:tw-mx-0 xl:tw-flex-col xl:tw-space-x-0 xl:tw-space-y-4">
            <li className="tw-flex tw-items-center md:tw-items-start xl:tw-items-center">
              <CheckCircleIcon className="tw-h-5 tw-w-5 tw-shrink-0 tw-text-slate-800 md:tw-h-6 md:tw-w-6 xl:tw-h-5 xl:tw-w-5" />
              <p className="tw-ml-3 tw-text-slate-700 lg:tw-ml-4 xl:tw-ml-5 xl:tw-text-lg">
                Engage and retain clients by giving them{' '}
                <span className="tw-font-medium tw-text-slate-900">feedback.</span>
              </p>
            </li>
            <li className="tw-flex tw-items-center md:tw-items-start xl:tw-items-center">
              <CheckCircleIcon className="tw-h-5 tw-w-5 tw-shrink-0 tw-text-slate-800 md:tw-h-6 md:tw-w-6 xl:tw-h-5 xl:tw-w-5" />
              <p className="tw-ml-3 tw-text-slate-700 lg:tw-ml-4 xl:tw-ml-5 xl:tw-text-lg">
                Create and share you link, it’s that{' '}
                <span className="tw-font-medium tw-text-slate-900">simple.</span>
              </p>
            </li>
            <li className="tw-flex tw-items-center md:tw-items-start xl:tw-items-center">
              <CheckCircleIcon className="tw-h-5 tw-w-5 tw-shrink-0 tw-text-slate-800 md:tw-h-6 md:tw-w-6 xl:tw-h-5 xl:tw-w-5" />
              <p className="tw-ml-3 tw-text-slate-700 lg:tw-ml-4 xl:tw-ml-5 xl:tw-text-lg">
                Get paid when you{' '}
                <span className="tw-font-medium tw-text-slate-900">give feedback.</span>
              </p>
            </li>
          </ul>
          <div className="tw-mt-10 tw-flex tw-flex-col sm:tw-flex-row sm:tw-space-x-5 md:tw-mt-12 md:tw-justify-center xl:tw-justify-start">
            {!user?.id ? (
              <Button href="/signup" className="tw-w-full sm:tw-w-auto">
                Get your free account
              </Button>
            ) : (
              <Button href={APP_URL} className="tw-w-full sm:tw-w-auto">
                Dashboard
              </Button>
            )}
            {/* <Button
              href="/signin"
              variant="ghost"
              className="tw-mt-3.5 tw-w-full sm:tw-mt-0 sm:tw-w-auto"
            >
              See our solutions
            </Button> */}
          </div>
        </div>
      </Container>

      <div className="tw-bg-gradient-to-r tw-from-vanilla tw-via-amber-50 tw-to-amber-100">
        <Container className="tw-relative">
          <div className="tw-mx-auto tw-max-w-lg tw-pb-8 tw-pl-4 tw-pt-20 md:tw-mx-0 md:tw-max-w-md lg:tw-max-w-lg lg:tw-pb-16 lg:tw-pt-28 xl:tw-max-w-xl xl:tw-py-12 xl:tw-pl-14 2xl:tw-pl-0">
            <blockquote>
              <div className="tw-relative tw-leading-relaxed tw-text-slate-700 xl:tw-text-lg">
                <svg
                  className="tw-absolute tw--top-12 tw-left-0 tw-h-8 tw-w-8 tw-transform tw-text-gray-secondary-200 xl:tw--left-5 xl:tw-top-0 xl:tw--translate-x-full xl:tw--translate-y-2"
                  height="48"
                  width="48"
                  viewBox="0 0 48 48"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <path
                      d="M21.66145,33.81676c0,4.29661-3.96109,8.22346-8.91304,8.22346C4.56585,42.04022,1,35.98671,1,27.90615 c0-9.27484,9.34862-18.21943,17.83035-21.94637l2.26574,3.64916C14.10766,12.9954,8.88433,17.58691,8.14413,25.28492h2.89106 c3.09587,0,6.31198,0.4991,8.45903,2.72402C21.02498,29.59761,21.66145,31.62025,21.66145,33.81676z M47,33.81676 c0,4.29661-3.96109,8.22346-8.91304,8.22346c-8.18256,0-11.74842-6.05352-11.74842-14.13408 c0-9.27484,9.34862-18.21943,17.83035-21.94637l2.26574,3.64916c-6.98843,3.38646-12.21176,7.97797-12.95195,15.67598 c3.15316,0,5.76908-0.11425,8.09925,0.71955C45.21084,27.30299,47,30.10812,47,33.81676z"
                      fill="currentColor"
                    />
                  </g>
                </svg>

                <p className="tw-relative">
                  The Feedback session app that makes it easy to ask and receive feedback. It allows
                  us to focus on what we love to do – and watch our students quickly improve.
                </p>
              </div>
              <div className="tw-mt-7 tw-flex tw-items-center">
                <div className="tw-h-14 tw-w-14 tw-rounded-full tw-bg-gray-secondary-100">
                  <Img
                    className="tw-h-full tw-w-full tw-rounded-full tw-object-cover tw-object-center"
                    width={56}
                    height={56}
                    src={Avatar}
                    alt="Home Hero Testimonial"
                    sizes="3.5rem"
                  />
                </div>
                <div className="tw-ml-5">
                  <p className="tw-font-medium tw-text-slate-900">Courtland Allen</p>
                  <p className="tw-font-medium tw-text-slate-500">Music Teacher</p>
                </div>
              </div>
            </blockquote>
          </div>
          <div className="tw-absolute tw-bottom-0 tw-right-6 tw-hidden tw-w-1/3 tw-bg-gray-secondary-100 md:tw-block lg:tw-right-12 xl:tw--right-0 xl:tw-w-full xl:tw-max-w-xl 2xl:tw--right-32 2xl:tw-max-w-[640px]">
            <Img
              src={homeHeroImg}
              className="tw-w-full tw-object-cover"
              style={{ height: '772px' }}
              alt="Podcaster recording a podcast with"
              sizes="(min-width: 1536px) 40rem, (min-width: 1280px) 36rem, (min-width: 768px) 33.33vw, 0"
            />
          </div>
        </Container>
      </div>
    </section>
  );
}
