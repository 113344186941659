import { Img } from 'react-image';
import { Link } from 'react-router-dom';

export default function Header() {
  return (
    <div className="tw-relative tw-overflow-hidden tw-bg-white">
      <div className="tw-mx-auto tw-max-w-7xl">
        <div className="tw-relative tw-z-10 tw-bg-white tw-pb-8 sm:tw-pb-16 md:tw-pb-20 lg:tw-w-full lg:tw-max-w-2xl lg:tw-pb-28 xl:tw-pb-32">
          <main className="tw-mx-auto tw-mt-10 tw-max-w-7xl tw-px-4 sm:tw-mt-12 sm:tw-px-6 md:tw-mt-16 lg:tw-mt-20 lg:tw-px-8 xl:tw-mt-28">
            <div className="sm:tw-text-center lg:tw-text-left">
              <h1 className="tw-leading-11 md:tw-leading-15 tw-text-4xl tw-font-extrabold tw-tracking-tight tw-text-gray-900 sm:tw-text-5xl md:tw-text-6xl">
                <span className="tw-block tw-text-blue-800 xl:tw-inline">
                  Feedback Based Learning
                </span>
              </h1>
              <p className="tw-mt-3 tw-text-base tw-leading-8 tw-text-gray-700 sm:tw-mx-auto sm:tw-mt-5 sm:tw-max-w-xl sm:tw-text-lg md:tw-mt-5 md:tw-text-2xl md:tw-leading-10 lg:tw-mx-0">
                Engage and retain clients by giving them feedback after a session. Accelerate their
                learning process so they keep coming back for more.
              </p>

              {/* {% include "./vanity-url.html" %} */}

              <div className="tw-mt-5 sm:tw-mt-8 sm:tw-flex sm:tw-justify-center lg:tw-justify-start">
                <div className="tw-rounded-md tw-shadow">
                  <Link
                    id="mbele-create-account-link"
                    to="/"
                    className="tw-default-bg-color tw-flex tw-w-full tw-items-center tw-justify-center tw-rounded-md tw-border tw-border-transparent tw-px-8 tw-py-3 tw-text-base tw-font-medium tw-text-white hover:tw-bg-blue-700 md:tw-px-10 md:tw-py-4 md:tw-text-lg"
                  >
                    Login
                  </Link>
                </div>
              </div>
            </div>
          </main>
        </div>
        <div className="tw-z-10 lg:tw-absolute lg:tw-inset-y-0 lg:tw-right-0 lg:tw-w-1/2">
          {/* // eslint-disable-next-line @next/next/no-img-element */}
          <Img
            className="tw-h-56 tw-w-full tw-object-cover sm:tw-h-72 md:tw-h-96 lg:tw-h-full lg:tw-w-full"
            src={require('images/landing/home/pexels-andrea-piacquadio-3762940-md.jpg')}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
