import Button from 'components/Elements/Button';
import { Container } from './components/Container';

export default function NotFound() {
  return (
    <section className="tw-bg-amber-100 tw-py-16 sm:tw-py-20">
      <Container>
        <div className="tw-mx-auto tw-max-w-xl tw-border tw-border-gray-secondary-400/60 tw-bg-gray-secondary-50 tw-px-8 tw-py-12 sm:tw-px-12 sm:tw-py-20 lg:tw-max-w-3xl">
          <h1 className="tw-text-center tw-text-6xl tw-font-semibold tw-text-slate-900 sm:tw-text-8xl">
            404
          </h1>
          <p className="tw-mx-auto tw-mt-5 tw-max-w-sm tw-text-center tw-text-lg tw-leading-relaxed tw-text-slate-700 sm:tw-mt-6 sm:tw-text-xl">
            Looks like the page you are looking for does not exist.
          </p>
          <div className="tw-mt-10 tw-flex tw-justify-center sm:tw-mt-12">
            <Button href="/" variant="ghost" size="md" className="tw-space-x-3.5">
              <svg
                height="24"
                width="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                className="tw-h-[18px] tw-w-[18px] tw-text-slate-700 tw-duration-150 tw-ease-in-out group-hover:tw-text-slate-50"
              >
                <g
                  fill="currentColor"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.25"
                >
                  <polyline fill="none" points="1 11 12 2 23 11" />
                  <polyline fill="none" points="10 23 10 17 14 17 14 23" stroke="currentColor" />
                  <path
                    d="M4,13v8c0,1.105,.895,2,2,2h12c1.105,0,2-.895,2-2V13"
                    fill="none"
                    stroke="currentColor"
                  />
                </g>
              </svg>

              <span>Go back home</span>
            </Button>
          </div>
        </div>
      </Container>
    </section>
  );
}
