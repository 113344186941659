import { ContactHeader } from './components/ContactHeader';
// import { ContactInfo } from './components/ContactInfo';
// import { Map } from './components/Map';
// import { FaqCTA } from './components/FaqCTA';
import { CallToAction } from './components/CallToAction';

export default function ContactPage() {
  return (
    <>
      <ContactHeader />
      {/* <ContactInfo /> */}
      {/* <Map /> */}
      {/* <FaqCTA /> */}
      <CallToAction />
    </>
  );
}
