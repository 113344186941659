import { ReactNode } from 'react';
import clsx from 'clsx';

interface MediaTextContainerProps {
  media: ReactNode;
  bgColor: string;
  title: string | ReactNode;
  content: string | ReactNode;
  align?: string;
}

export function MediaTextContainer({
  media,
  bgColor,
  title,
  content,
  align,
}: MediaTextContainerProps) {
  return (
    <div>
      <div className="tw-mx-auto tw-my-20 tw-grid tw-max-w-7xl tw-items-start tw-gap-y-20 tw-px-4 sm:tw-px-6 lg:tw-my-32 lg:tw-grid-flow-col lg:tw-grid-cols-7 lg:tw-gap-x-28 lg:tw-px-8">
        <div
          className={clsx(
            'tw-order-first tw-max-w-2xl tw-pt-20 lg:tw-col-span-4 lg:tw-pb-20 lg:tw-pt-0',
            align === 'tw-left' ? 'lg:tw-order-first' : 'lg:tw-order-last',
          )}
        >
          <div className="tw-relative">
            <div className="tw-bg-subtle tw-absolute tw-inset-0 tw-z-0 tw-rotate-12 tw-scale-110 tw-transform tw-p-10 tw-opacity-80"></div>
            <div
              className={clsx(
                `tw-bg-subtle tw-absolute tw-inset-0 tw-z-0 tw-transform tw-rounded-2xl tw-p-10`,
                bgColor,
                align === 'tw-left' ? 'tw-rotate-6' : 'tw--rotate-6',
              )}
            ></div>
            <div className="tw-relative tw-z-10 tw-overflow-hidden tw-rounded-lg tw-shadow-xl">
              {media}
            </div>
          </div>
        </div>
        <div className="lg:tw-col-span-3">
          <div>
            <div className="tw-relative">
              <div
                className={clsx(`tw-h-2 tw-w-24 tw-rounded-full tw-bg-opacity-25`, bgColor)}
              ></div>
              <div
                className={clsx(
                  `tw-absolute tw-top-0 tw-h-2 tw-w-16 tw-rounded-full tw-bg-opacity-50`,
                  bgColor,
                )}
              ></div>
              <div
                className={clsx(
                  `tw-absolute tw-top-0 tw-h-2 tw-w-8 tw-rounded-full tw-bg-opacity-100`,
                  bgColor,
                )}
              ></div>
            </div>
            <h3 className="tw-mt-4 tw-text-2xl tw-font-black tw-tracking-tight sm:tw-text-3xl lg:tw-text-4xl">
              {title}
            </h3>
            <div className="tw-prose sm:tw-prose-xl tw-opacity-75">{content}</div>
          </div>
          {/* <!----> */}
          {/* <div className="mt-8">
						<a
							href="/demo"
							className={`button button-xl button-${bgColor}`}
						>
							Try It On The Demo →{" "}
						</a>
					</div> */}
        </div>
      </div>
    </div>
  );
}

interface FeatureListProps {
  title: string;
  content: string;
}

interface FeatureTextListProps {
  title: ReactNode;
  featureList: FeatureListProps[];
  bgColor: string;
}

export function FeatureListContaner({ title, featureList, bgColor }: FeatureTextListProps) {
  return (
    <div>
      <div className="tw-my-20 lg:tw-my-32">
        <div className="tw-mx-auto tw-grid tw-max-w-7xl tw-gap-4 tw-px-4 sm:tw-grid-cols-2 sm:tw-px-6 md:tw-grid-cols-3 lg:tw-grid-cols-4 lg:tw-px-8">
          <div
            className={clsx(
              `tw-flex tw-rounded-2xl tw-bg-indigo-500 tw-p-6 tw-text-white sm:tw-rounded-br-none`,
              bgColor,
            )}
          >
            <h4 className="tw-m-0 tw-text-base tw-font-bold tw-uppercase tw-tracking-wider">
              {title}
            </h4>
          </div>

          {featureList.map(item => (
            <div key={item.title}>
              <div className="tw-h-full tw-rounded-xl tw-bg-white tw-p-6">
                <h5 className="tw-text-base tw-font-medium">{item.title}</h5>
                <p className="tw-text-gray-500">{item.content}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
