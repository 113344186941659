import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookF, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

function LinkContainer({ href, text }: { href: string; text: string }) {
  return (
    <Link to={href}>
      <span className="tw-cursor-pointer tw-font-light md:hover:tw-underline">{text}</span>
    </Link>
  );
}

export default function Footer() {
  return (
    <footer id="overview" className="tw-default-bg-color">
      <div className="tw-container tw-mx-auto tw-p-2 md:tw-p-4">
        <div className="tw-inner-container tw-relative tw-z-10 tw-px-4 tw-py-10 md:tw-py-12 lg:tw-px-8">
          <div className="tw-flex tw-flex-col md:tw-flex-row">
            <div className="tw-mb-14 tw-grid tw-grid-cols-6 tw-gap-6 sm:tw-gap-14 md:tw-mb-0 md:tw-ml-auto lg:tw-flex">
              <div className="tw-col-span-3 tw-text-white">
                <h6 className="tw-mb-4 tw-font-semibold">Product</h6>
                <div className="tw-flex tw-flex-col tw-gap-2 md:tw-justify-start">
                  <LinkContainer href="/page/features" text="Features" />

                  <LinkContainer href="/page/sales" text="Sales" />

                  <LinkContainer href="#integrations" text="Integrations" />

                  <LinkContainer href="#roadmap" text="Roadmap" />
                  {/*
									<a
										className="font-light"
										href="#integrations"
									>
										Integrations
									</a>
									<a className="font-light" href="#changelog">
										Roadmap
									</a> */}
                </div>
              </div>

              <div className="tw-col-span-3">
                <div className="tw-flex tw-flex-col tw-justify-center tw-text-white md:tw-justify-start">
                  <h6 className="tw-mb-4 tw-font-semibold">Support</h6>

                  <div className="tw-flex tw-flex-col tw-gap-2 md:tw-justify-start">
                    <a
                      className="tw-cursor-pointer tw-font-light md:hover:tw-underline"
                      href="https://helpdesk.mbele.io/"
                    >
                      Helpdesk
                    </a>
                    <a
                      className="tw-cursor-pointer tw-font-light md:hover:tw-underline"
                      href="https://mbele.statuspage.io"
                    >
                      Status
                    </a>
                    <a
                      className="tw-cursor-pointer tw-font-light md:hover:tw-underline"
                      href="mailto:info@mbele.io"
                    >
                      Contact
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="tw-col-span-6" />

            <div className="md:tw-order-first md:tw-mr-14">
              <div className="tw-flex tw-flex-col tw-py-2 tw-text-white">
                <h4 className="tw-block tw-text-2xl tw-font-medium tw-tracking-wider">Mbele</h4>

                <p className="tw-mb-4 tw-mt-1 tw-text-xl tw-font-light">
                  Feedback Based Learning to retain clients
                </p>
              </div>

              <div className="tw-flex tw-flex-row tw-py-2 tw-text-white">
                <a className="tw-mr-4 tw-underline" href="https://mbele.io/terms_of_service/">
                  Terms of use
                </a>

                <a className="tw-underline" href="https://mbele.io/privacy_policy_mbele/">
                  Privacy
                </a>
              </div>

              <div className="tw-flex tw-flex-row tw-text-white">
                <a className="tw-mr-6" href="https://www.facebook.com/Mbele-106173060896796/">
                  {' '}
                  <FontAwesomeIcon icon={faFacebookF} className="tw-text-2xl" />
                </a>
                <a className="tw-mr-6" href="https://www.instagram.com/mbele.io/">
                  {' '}
                  <FontAwesomeIcon icon={faInstagram} className="tw-text-2xl" />
                </a>
                <a
                  className="tw-mr-6"
                  href="https://www.youtube.com/channel/UCgqIArT1HDwTzQoaoONUsOw/"
                >
                  {' '}
                  <FontAwesomeIcon icon={faYoutube} className="tw-text-2xl" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tw-w-full tw-bg-blue-800 tw-p-2 tw-text-center tw-text-white">
        <div className="tw-mb-1">
          Made with <FontAwesomeIcon icon={faHeart} className="tw-text-sm" /> in Toronto.
        </div>
        <span>Copyright © 2024. All rights reserved.</span>
      </div>
    </footer>
  );
}
