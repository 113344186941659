import { HomeHero } from './components/HomeHero';
import { FeatureBlocks } from './components/FeatureBlocks';
import { FeaturesGrid } from './components/FeaturesGrid';
import { Process } from './components/Process';
import { Faqs } from './components/Faqs';
import { CallToAction } from './components/CallToAction';

export default function HomePage() {
  return (
    <>
      <HomeHero />
      <FeatureBlocks />
      <FeaturesGrid />
      <Process />
      <Faqs />
      <CallToAction />
    </>
  );
}
