import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';

import firebase from 'store/firebase';
import store from 'store/configure';
import App from 'containers/App/landing';
import ThemeConfig from 'theme';
import GlobalStyles from 'theme/globalStyles';
import AuthProvider from 'context/AuthContext';
import AppProvider from 'context/AppContext';

import './tailwind-landing.css';

const rrfConfig = {};
const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <BrowserRouter>
          <CssBaseline />
          <ThemeConfig>
            <GlobalStyles />
            <AppProvider app="main">
              <AuthProvider>
                <App />
              </AuthProvider>
            </AppProvider>
          </ThemeConfig>
        </BrowserRouter>
      </ReactReduxFirebaseProvider>
    </Provider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
// serviceWorker.register({ firebase });
