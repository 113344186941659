import Header from './components/Home/Header';
import AppMedia from './components/Home/AppMedia';
import HowItWorks from './components/Home/HowItWorks';
import WhenWorks from './components/Home/WhenWorks';
import Integrations from './components/Home/Integrations';

const Home = () => {
  return (
    <div>
      <div id="main" className="main-content-wraper">
        <div className="main-content-inner">
          <Header />

          <AppMedia containerClass="tw-bg-secondary" />

          <HowItWorks />

          <WhenWorks />

          <Integrations containerClass="tw-bg-secondary" />
        </div>
      </div>
    </div>
  );
};

export default Home;
