import { Img } from 'react-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faFileAlt, faCommentDots } from '@fortawesome/free-regular-svg-icons';
import { faPhone, faVideo } from '@fortawesome/free-solid-svg-icons';

interface Props {
  containerClass?: string;
}

const integrationList = [
  {
    src: require('images/landing/logos/mindbody-logo.png'),
    alt: 'mind body',
    width: 200,
    height: 70,
  },
  {
    src: require('images/landing/logos/twilio-logo.png'),
    alt: 'twilio',
    width: 180,
    height: 70,
  },
  {
    src: require('images/landing/logos/aws-cloud9-logo.png'),
    alt: 'twilio',
    width: 100,
    height: 80,
  },
  {
    src: require('images/landing/logos/gdrive-logo.png'),
    alt: 'twilio',
    width: 80,
    height: 60,
  },
  {
    src: require('images/landing/logos/stripe-logo.png'),
    alt: 'twilio',
    width: 180,
    height: 70,
  },
];

const integrationAdditionalList = [
  {
    heading: 'Online payments',
    body: 'We support payments in all currencies using Stripe or PayPal.',
    icon: <FontAwesomeIcon icon={faCreditCard} className="text-2xl" />,
  },
  {
    heading: 'Video recordings',
    body: 'Store your recordings in your google drive or dropbox to review client progress.',
    icon: <FontAwesomeIcon icon={faVideo} className="text-2xl" />,
  },
  {
    heading: 'Embedded Page',
    body: 'Embed your mbele.io page directly onto your business website to make it easier for your client to find their instructor.',
    icon: <FontAwesomeIcon icon={faFileAlt} className="text-2xl" />,
  },
  {
    heading: 'Private Messaging',
    body: 'Secure DMs right on the platform, all in one place.',
    icon: <FontAwesomeIcon icon={faCommentDots} className="text-2xl" />,
  },
  {
    heading: 'Professional support',
    body: 'Our team will help you set up your account, and become successful.',
    icon: <FontAwesomeIcon icon={faPhone} className="text-2xl" />,
  },
];

export default function Integrations({ containerClass }: Props) {
  return (
    <section
      id="how"
      className="tw-mb-20 tw-mt-8 tw-flex tw-flex-col tw-items-center tw-justify-center"
    >
      <div
        className={`${containerClass} tw-container tw-w-full tw-rounded-2xl tw-py-10 md:tw-w-10/12`}
      >
        <div className="tw-mx-8 sm:tw-mx-5 md:tw-mx-12">
          <h2 className="tw-mb-2 tw-py-3 tw-text-center tw-text-4xl tw-font-normal tw-leading-10 tw-text-blue-700 md:tw-leading-6">
            Mbele Integrations
          </h2>

          <h3 className="tw-mb-4 tw-py-3 tw-text-center tw-text-xl tw-font-light tw-leading-10 md:tw-mx-48">
            Mbele integrations will help your clients get the most out of your sessions and boost
            their learning and engagement.
          </h3>

          <div className="tw-flex tw-flex-col tw-items-center tw-justify-between md:tw-flex-row">
            {integrationList.map(item => (
              <span key={item.src}>
                <Img
                  src={item.src}
                  alt={item.alt}
                  width={item.width}
                  height={item.height}
                  style={{ objectFit: 'contain' }}
                />
              </span>
            ))}
          </div>

          <h3 className="tw-mb-4 tw-mt-4 tw-py-3 tw-text-center tw-text-2xl tw-font-light tw-leading-10 md:tw-mx-48">
            Additionally you also get the following
          </h3>

          <div className="tw-grid tw-grid-cols-1 tw-gap-x-[76px] tw-gap-y-10 tw-text-sm sm:tw-grid-cols-2 md:tw-grid-cols-3 md:tw-text-base">
            {integrationAdditionalList.map(item => (
              <div
                className="tw-flex tw-flex-col tw-items-center tw-text-center sm:tw-items-start sm:tw-text-left"
                key={item.heading}
              >
                <div className="tw-mb-4">{item.icon}</div>
                <h3 className="tw-h5 tw-mb-2 tw-font-semibold">{item.heading}</h3>
                <p>{item.body}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
