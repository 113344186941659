import clsx from 'clsx';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

import { Container } from './Container';
import Button from 'components/Elements/Button';
import { userAuth as userAuthApi } from 'store/index';
import { useSelector } from 'hooks';
import { UserAuthType } from 'types/index';
import { APP_URL } from 'utils/constants';

const tiers = [
  {
    name: 'Free',
    href: '#',
    description: 'Enjoy 5 free requests every month—pay only when you see the value.',
    price: 0,
    features: [
      '5 free requests per month',
      'Get requests via chat, audio, or video',
      'Video calls and scheduling',
      'Automated notifications',
      'Eventbrite integration',
      'Analytics dashboard',
      'Integrated payments',
      'Upload and share documents',
    ],
  },
  {
    name: 'Standard',
    href: '#',
    description: 'A simple pricing model designed to boost learning engagement.',
    price: 29,
    features: ['Everything in free tier', 'Including unlimited requests'],
  },
];

export function PricingCards() {
  const user: UserAuthType = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);

  return (
    <section className="tw-relative tw-overflow-hidden tw-bg-amber-100 tw-pt-16 sm:tw-pt-24">
      <Container>
        <div className="tw-mx-auto tw-flex tw-max-w-lg tw-flex-col tw-items-center sm:tw-max-w-xl md:tw-max-w-2xl lg:tw-mx-0 lg:tw-max-w-none">
          <h1 className="tw-text-center tw-text-4xl tw-font-semibold tw-leading-snug tw-text-slate-900 sm:tw-text-5xl sm:tw-leading-snug md:tw-mx-auto md:tw-max-w-4xl xl:tw-mx-0">
            One size fits all plan
          </h1>
          <p className="tw-mx-auto tw-mt-5 tw-max-w-xl tw-text-center tw-text-lg tw-leading-relaxed tw-text-slate-700 sm:tw-mt-6">
            Pay one flat rate and whatever income you make on the app you get to keep. No hidden
            costs and fees, what you see is what you get.
          </p>
        </div>
      </Container>
      <div className="tw-relative tw-pt-20">
        <div className="tw-absolute tw-inset-0 tw-flex tw-flex-col" aria-hidden="true">
          <div className="tw-flex-1 tw-bg-amber-100" />
        </div>
        <Container className="tw-flex tw-justify-center">
          {/*
          <div className="tw-relative tw-mx-auto tw-grid tw-max-w-lg tw-divide-x-0 tw-divide-y tw-divide-gray-secondary-400/75 sm:tw-max-w-xl md:tw-max-w-2xl lg:tw-mx-0 lg:tw-max-w-none lg:tw-grid-cols-3 lg:tw-divide-x lg:tw-divide-y-0"> */}
          <div
            className={clsx(
              'tw-relative tw-mx-auto',
              'tw-max-w-lg sm:tw-max-w-xl md:tw-max-w-2xl lg:tw-mx-0 lg:tw-max-w-[800px]',
              'tw-gap-1.5',
              'tw-grid lg:tw-grid-cols-2',
              // 'tw-divide-gray-secondary-400/75 tw-divide-x-0 tw-divide-y lg:tw-divide-x lg:tw-divide-y-0'
              // 'tw-border tw-border-gray-secondary-400/60',
            )}
          >
            {tiers.map((tier, index) => (
              <div
                key={tier.name}
                className={clsx(
                  index == 2 ? 'tw-bg-purple-light' : 'tw-bg-gray-secondary-50',
                  'tw-p-8 sm:tw-p-10',
                  'tw-border tw-border-gray-secondary-400/60',
                  // 'tw-col-start-2',
                )}
              >
                <h3 className="tw-text-lg tw-font-semibold tw-text-slate-900">{tier.name}</h3>
                <p className="tw-mt-4 tw-text-slate-600">{tier.description}</p>
                <p className="tw-mt-12">
                  <span className="tw-text-4xl tw-font-semibold tw-text-slate-900">
                    ${tier.price}
                  </span>
                  <span className="tw-ml-2.5 tw-text-lg tw-font-medium tw-text-slate-500">
                    / month
                  </span>
                </p>
                {!user?.id ? (
                  <Button href="/signup" className="tw-mt-8 tw-w-full">
                    Create an account.
                  </Button>
                ) : (
                  <Button href={APP_URL} className="tw-mt-8 tw-w-full">
                    Dashboard
                  </Button>
                )}
                <p className="tw-mt-3.5 tw-text-center tw-text-sm tw-text-slate-500">
                  No credit card required
                </p>
                <hr className="tw-my-10 border-gray-secondary-400/30" />
                <p className="tw-font-medium tw-text-slate-900">{tier.name} includes:</p>
                <ul className="tw-mt-6 tw-space-y-4">
                  {tier.features.map(feature => (
                    <li key={feature} className="tw-flex tw-items-center tw-space-x-6">
                      <CheckCircleIcon className="tw-h-5 tw-w-5 tw-shrink-0 tw-text-slate-800" />
                      <p className="tw-text-slate-600">{feature}</p>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </Container>
      </div>
    </section>
  );
}
